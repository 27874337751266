import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const getPartnersData = async (emitentId) => {
  const response = await fetch(
    `${SERVER_URL}/getPartnersData?emitentId=${emitentId}&authSession=${sessionLocalStore.getItem('session')}`
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  if (response.status === 404) {
    return []
  }
  return response.json()
}
export default getPartnersData
