import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Spinner from '../Spinner'

const Loadable = props => (
  props.loading ? <Spinner /> : props.children
)
Loadable.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
}
const mapStateToProps = state => ({ loading: state.apiCallsInProgress > 0 })
export default connect(mapStateToProps)(Loadable)
