import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Counter from 'components/Counter'

const CodeSetsCounter = ({ codeSetsAmount }) => (
  <Counter amount={codeSetsAmount} />
)
CodeSetsCounter.propTypes = {
  codeSetsAmount: PropTypes.number.isRequired,
}
const mapStateToProps = state => ({ codeSetsAmount: state.codeSetList.length })
export default connect(mapStateToProps)(CodeSetsCounter)
