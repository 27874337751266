import React from 'react'
import PartnersViewBackground from './PartnersViewBackground'
import PartnersContentContainer from './PartnersContentContainer'
import PartnersList from './PartnersList/PartnersList'
import PartnersFeaturesContainer from './PartnersFeaturesContainer'
import HorizontalDivider from '../../components/HorizontalDivider'

const PartnersView = () => (
  <React.Fragment>
    <PartnersViewBackground />
    <HorizontalDivider />
    <PartnersContentContainer>
      <PartnersFeaturesContainer />
      <PartnersList />
    </PartnersContentContainer>
  </React.Fragment>
)

export default PartnersView
