import React from 'react'
import PropTypes from 'prop-types'

const PlainContainer = ({
  children,
  widthInVw,
  heightInVh,
  customStyles = {},
}) => (
  <div style={{
    width: `${widthInVw}vw`,
    height: `${heightInVh}vh`,
    borderRadius: '5px',
    paddingBottom: '40px',
    boxShadow: '2px 9px 15px 3px rgba(0,0,0,0.16)',
    backgroundColor: '#fff',
    marginTop: '10px',
    ...customStyles,
  }}
  >
    {children}
  </div>
)

PlainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heightInVh: PropTypes.number,
  widthInVw: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  customStyles: PropTypes.object,

}
PlainContainer.defaultProps = {
  customStyles: {},
  heightInVh: null,
  widthInVw: null,
}
export default PlainContainer
