import React from 'react'
import PropTypes from 'prop-types'

const ContentContainer = ({ children, top }) => (
  <div style={{
    width: '82vw',
    paddingTop: '20px',
    top: `${top}`,
    left: '9vw',
    position: 'absolute',
  }}
  >
    {children}
  </div>
)
ContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  top: PropTypes.string.isRequired,
}
export default ContentContainer
