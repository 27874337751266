/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import bigPlus from 'icons/bigPlus.svg'
import {
  required, length, validateForm, url, numericality, format,
} from 'redux-form-validators'
import CreateTokenFinal from '../CreateTokenFinal'
import {
  onTokenSubmit,
  clearNewTokenForm,
  fetchAudiobookData,
  resetAudiobookDataInForm,
} from '../../../redux/action'

import style from './style.module.css'

class CreateTokenFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      QRModalVisible: false,
      logoImgSrc: null,
      pozId: null,
      audiobook: null,
      tokensType: 'NORMAL',
    }
  }

  componentWillUnmount = () => {
    this.props.clearNewTokenForm()
    this.props.resetAudiobookDataInForm()
  }

  onSubmit = (formVal) => {
    this.props.onTokenSubmit(formVal)
    this.toggleVisibility(true)
  }

  toggleVisibility = (isVisible) => {
    this.setState({ QRModalVisible: isVisible })
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }

  changeTokensType = (formProp, e) => {
    formProp.input.onChange(e)
    this.setState({ tokensType: e.target.value })
  }

  getAudiobookByPozId = async () => {
    this.props.fetchAudiobookData(this.state.pozId)
  }

  handleFileChange = (formProp, e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    formProp.input.onChange(e)
    const fileReader = new FileReader()
    fileReader.onload = (ev) => {
      this.setState({
        logoImgSrc: ev.target.result,
      })
    }
    fileReader.readAsDataURL(file)
  }

  renderInput = formProp => (
    <div style={{
      gridColumn: `${formProp.gridColumn}`,
      gridRow: `${formProp.gridRow}`,
    }}
    >
      <label className={style.label} htmlFor={formProp.label}>
        {formProp.label}
      </label>
      <input
        id={formProp.label}
        className={style.token_input}
        min={0}
        type={formProp.type}
        {...formProp.input}
        autoComplete="off"
      />
      <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
    </div>
  )

  renderTextArea = formProp => (
    <div style={{
      gridColumn: `${formProp.gridColumn}`,
      gridRow: `${formProp.gridRow}`,
    }}
    >
      <label className={style.label} htmlFor={formProp.label}>
        {formProp.label}
      </label>
      <textarea
        id={formProp.label}
        className={style.token_textArea}
        {...formProp.input}
        autoComplete="off"
      />
      <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
    </div>
  )

  renderSelect = formProp => (
    <div style={{
      gridColumn: `${formProp.gridColumn}`,
      gridRow: `${formProp.gridRow}`,
    }}
    >
      <label className={style.label} htmlFor={formProp.label}>
        {formProp.label}
      </label>
      <select
        id={formProp.label}
        className={style.token_select}
        style={{
          backgroundColor: '#DDDDDD',
          paddingLeft: '0px',
          textAlignLast: 'center',
          textAlign: 'center',
        }}
        value={formProp.value ? formProp.value : this.state.tokensType}
        onChange={value => this.changeTokensType(formProp, value)}
        {...formProp.label}
      >
        {formProp.options.map(opt => (
          <option
            key={opt.value}
            value={opt.value}
            style={{
            }}
          >
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  )

  renderFileInput = (formProp) => {
    // eslint-disable-next-line no-param-reassign
    delete formProp.input.value
    return (
      <div style={{
        position: 'relative',
        gridColumn: `${formProp.gridColumn}`,
        gridRow: `${formProp.gridRow}`,
      }}
      >
        <label className={style.label} htmlFor={formProp.label}>
          {formProp.label}
        </label>
        <label className={style.file_label} htmlFor={formProp.label}>
          <img
            style={{
              borderRadius: '50%',
              width: '35%',
              height: '35%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',

            }}
            src={this.state.logoImgSrc || bigPlus}
            alt="plus icon"
          />
        </label>
        <input
          {...formProp.input}
          onChange={value => this.handleFileChange(formProp, value)}
          onBlur={() => { /* left empty to fix redux-form overrides */ }}
          style={{
            position: 'absolute',
            left: '-10000px',
            top: '-10000px',
          }}
          type="file"
          id={formProp.label}
        />
        <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
      </div>
    )
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>
    }
    return null
  }

  render() {
    return (
      <>
        <div style={{ width: 600, display: 'flex', margin: '0 50px 20px 20px' }}>
          <label style={{ display: 'flex', alignItems: 'center' }} className="label">Poz id: </label>
          <input
            id="pozId"
            name="pozId"
            type="text"
            autoComplete="off"
            value={this.state.audiobook}
            onChange={e => this.handleChange(e)}
            style={{ display: 'inline-block', width: 200, float: 'left' }}
          />
          <button
            type="button"
            onClick={() => { this.getAudiobookByPozId() }}
            style={{
              width: 200,
              height: 40,
              borderRadius: 8,
              float: 'left',
              padding: '0.5em',
              backgroundColor: '#fff',
            }}
          >
            Get audiobook
          </button>

          <button
            type="button"
            onClick={() => { this.props.resetAudiobookDataInForm() }}
            style={{
              width: 150,
              height: 40,
              borderRadius: 8,
              float: 'left',
              padding: '0.5em',
              margin: '0 0 0 5px',
              backgroundColor: '#fff',
            }}
          >
            Reset all fields
          </button>
        </div>

        <PlainContainer
          heightInVh={70}
          widthInVw={75}
          customStyles={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <form className={style.token_form} onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field
              name="name"
              component={this.renderInput}
              label="name"
              type="text"
              gridColumn="1/3"
              gridRow="1/2"
            />
            <Field
              name="tokenSymbol"
              component={this.renderInput}
              label="token symbol"
              type="text"
              gridColumn="1/2"
              gridRow="2/3"
            />
            <Field
              name="valueInBaseToken"
              component={this.renderInput}
              label="value"
              type="number"
              gridColumn="2/3"
              gridRow="2/3"
            />
            <Field
              name="description"
              component={this.renderTextArea}
              label="description"
              type="text"
              gridColumn="1/3"
              gridRow="3/5"
            />
            <Field
              name="promoTermsUrl"
              component={this.renderInput}
              label="link to the terms of the promotion"
              type="text"
              gridColumn="3/4"
              gridRow="1/2"
            />
            {/* <Field
              name="tokensType"
              component={this.renderSelect}
              label="tokens type"
              options={[
                { value: 'internal', label: 'internal' },
                { value: 'external', label: 'external' },
              ]}
              gridColumn="3/4"
              gridRow="2/3"
            /> */}
            <Field
              name="tokensType"
              component={this.renderSelect}
              label="tokens type"
              options={[
                { value: 'NORMAL', label: 'normal' },
                { value: 'STREAMING', label: 'streaming' },
                { value: 'AUDIOBOOK', label: 'audiobook' },
              ]}
              gridColumn="3/4"
              gridRow="2/3"
            />
            {this.state.tokensType === 'STREAMING'
              ? (
                <Field
                  name="expirationTime"
                  component={this.renderInput}
                  label="Token expiration (in days)"
                  type="text"
                  gridColumn="3/4"
                  gridRow="5/5"
                />
              ) : null
              }
            <Field
              name="amountPerReward"
              component={this.renderInput}
              type="number"
              label="amount per reward"
              gridColumn="3/4"
              gridRow="3/4"
            />
            <Field
              name="amount"
              component={this.renderInput}
              label="supply"
              type="number"
              gridColumn="3/4"
              gridRow="4/5"
            />
            <Field
              name="ref_id"
              component={this.renderInput}
              label="ref_id"
              type="number"
              gridColumn="1/2"
              gridRow="5/6"
            />
            <Field
              name="logoFile"
              component={this.renderFileInput}
              accept=".jpg, .png, .jpeg, .svg, .gif, .webp"
              label="graphics"
              gridColumn="/5"
              gridRow="1/5"
            />
            <Field
              name="technicalDetails"
              component={this.renderTextArea}
              label="technicalDetails"
              type="text"
              // gridColumn="1/-1"
              gridColumn="2/6"
              gridRow="6/6"
              defaultValue="some technical details"
            />
            <button
              className={this.props.invalid ? style.token_button_disable : style.token_button}
              type="submit"
            >
              Create Token

            </button>
          </form>
          {this.props.error && (
            <div style={{
              fontSize: '0.8em',
              color: 'rgb(148, 69, 69)',
              marginLeft: '10px',
              textTransform: 'uppercase',
            }}
            >
              {this.props.error}
            </div>
          )}
        </PlainContainer>
        {(this.props.isNewTokenCreated && this.state.QRModalVisible)
          && (
            <CreateTokenFinal
              toggleVisibility={this.toggleVisibility}
              from="dwe234fk1aff1"
              tokenId={this.props.newTokenId}
              amount={this.props.formVal.values.amount}
            />
          )}
      </>
    )
  }
}

CreateTokenFormContainer.propTypes = {
  onTokenSubmit: PropTypes.func.isRequired,
  fetchAudiobookData: PropTypes.func.isRequired,
  isNewTokenCreated: PropTypes.bool.isRequired,
  newTokenId: PropTypes.number.isRequired,
  // redux form properties, docs: https://redux-form.com/7.4.2/docs/api/props.md/
  // eslint-disable-next-line react/forbid-prop-types
  formVal: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  clearNewTokenForm: PropTypes.func.isRequired,
  resetAudiobookDataInForm: PropTypes.func.isRequired,
}
CreateTokenFormContainer.defaultProps = {
  error: null,
}

const validate = validateForm({
  name: [required(),
  // @ts-ignore
    format({
    // eslint-disable-next-line no-useless-escape
      without: /[!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]/i,
    }),
  ],
  tokenSymbol: [required(),
  // eslint-disable-next-line no-useless-escape
    format({
    // eslint-disable-next-line no-useless-escape
      without: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/i,
    }),
    length({
      max: 8,
      msg: { tooLong: 'must be 8 chars or less' },
    }),
  ],
  valueInBaseToken: [required(), numericality({
    '>=': 0,
    msg: { '>=': 'Value must be 0 or greater' },
  })],
  description: required(),
  promoTermsUrl: [required(), url()],
  amountPerReward: [required(), numericality({
    '>=': 0,
    msg: { '>=': 'Value must be 0 or greater' },
  })],
  amount: [required(), numericality({
    '>': 0,
    msg: { '>': 'Value must greater than 0' },
  })],
  logoFile: [required()],
  // expirationTime: [required(), numericality({
  //   '>=': 0,
  //   msg: { '>=': 'Value must be 0 or greater' },
  // })],
})

const connectedReduxForm = reduxForm({
  form: 'createToken',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(CreateTokenFormContainer)

export default connect(
  state => ({
    isNewTokenCreated: !!state.newToken.createdTokenId,
    newTokenId: state.newToken.createdTokenId,
    formVal: state.form.createToken,
    error: state.newToken.error,
    initialValues: {
      tokensType: 'NORMAL',
      ...state.audiobooks,
    },
  }),
  {
    onTokenSubmit,
    clearNewTokenForm,
    fetchAudiobookData,
    resetAudiobookDataInForm,
  }
)(connectedReduxForm)

