import { SERVER_URL } from '../utils/constants'

const loginEmitent = async (sessionId) => {
  const response = await fetch(
    `${SERVER_URL}/loginEmitent?sessionId=${sessionId}`
  )

  if (response.status === 404) {
    return { success: false }
  }
  const { session, emitentUsersList, username } = await response.json()

  return ({
    success: true, session, emitentUsersList, username,
  })
}
export default loginEmitent
