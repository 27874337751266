import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logOut } from '../../../utils/logOut'
// import optionsIcon from './options.svg'
import logoutIcon from './logout.svg'
import TopBarButton from './TopBarButton'

const TopBarButtonsPanel = ({ logOutUser }) => (
  <div style={{
    position: 'absolute',
    top: '32px',
    right: '2vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }}
  >
    {/* <TopBarButton iconSrc={optionsIcon} label="Options" /> */}
    <TopBarButton
      iconSrc={logoutIcon}
      label="Logout"
      onClick={logOutUser}
      width="calc(1.8vw + 16px)"
    />
  </div>
)

const mapDispatchToProps = dispatch => ({
  logOutUser: () => logOut(dispatch),
})

TopBarButtonsPanel.propTypes = {
  logOutUser: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(TopBarButtonsPanel)
