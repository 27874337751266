import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ text, isDisabled, styleOptions }) => (
  <button
    type="button"
    disabled={isDisabled}
    style={{
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: 'white',
      boxShadow: '2px 4px 18px -5px rgba(0,0,0,0.31)',
      color: '#604E97',
      fontSize: 'calc(0.5vw + 6px)',
      borderRadius: '8px',
      padding: '10px 30px',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      right: 0,
      ...styleOptions,
    }}
  >
    {text}
  </button>
)
Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,
  isDisabled: PropTypes.bool,
}
Button.defaultProps = {
  styleOptions: {},
  isDisabled: false,
}
export default Button
