import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TopBarNavigationElement from './TopBarNavigationElement'

const TopBarNavigation = ({ location }) => {
  const path = location.replace(`${process.env.PUBLIC_URL}/`, '')
  return (
    <div style={{
      position: 'absolute',
      left: '9vw',
      top: 24,
      display: 'flex',
    }}
    >
      <Link to={`${process.env.PUBLIC_URL}/tokens`}>
        <TopBarNavigationElement
          isFirst
          status={path.startsWith('tokens') ? 'active' : null}
        >
          Tokens
        </TopBarNavigationElement>
      </Link>
      <Link to={`${process.env.PUBLIC_URL}/partners`}>
        <TopBarNavigationElement status={path.startsWith('partners') ? 'active' : null}>
          Partners
        </TopBarNavigationElement>
      </Link>
      <Link to={`${process.env.PUBLIC_URL}/codesets`}>
        <TopBarNavigationElement status={path.startsWith('codesets') ? 'active' : null}>
          Code sets
        </TopBarNavigationElement>
      </Link>
      <Link to={`${process.env.PUBLIC_URL}/transactions`}>
        <TopBarNavigationElement isLast status={path.startsWith('transactions') ? 'active' : null}>
          Transactions
        </TopBarNavigationElement>
      </Link>
    </div>
  )
}
TopBarNavigation.propTypes = {
  location: PropTypes.string.isRequired,
}
export default TopBarNavigation
