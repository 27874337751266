import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const deleteCodeSet = async (address) => {
  const response = await fetch(
    // eslint-disable-next-line max-len
    `${SERVER_URL}/deleteCodeSet?address=${address}&authSession=${sessionLocalStore.getItem('session')}`,
    {
      method: 'post',
    }
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  return response.json()
}

export default deleteCodeSet
