import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'
import backArrow from 'icons/backArrow.svg'
import { selectToken } from '../../../redux/action'
import style from './style.module.css'

class TokenInfoFeaturesContainer extends React.Component {
  handleSelectChange = (e) => {
    this.props.selectToken(e.target.value)
    this.props.history.push(`${process.env.PUBLIC_URL}/tokens/info/${e.target.value}`)
  }

  render() {
    const { tokensNames, selectedToken } = this.props
    return tokensNames.length ? (
      <FeaturesContainer heightInVh={6}>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 'calc(0.5vw + 6px)',
        }}
        >
          <TableDescription
            styleOptions={{
              width: '16%',
              height: '6vh',

            }}
            description={(
              <Link
                style={{
                  display: 'flex',
                  justifyContent: 'baseline',
                  alignItems: 'center',
                  height: '100%',
                }}
                to={`${process.env.PUBLIC_URL}/tokens`}
              >
                <img style={{ marginRight: '25px' }} src={backArrow} alt="go back" />
                {tokensNames.find(objName => objName.tokenSymbol.toLowerCase() === selectedToken.toLowerCase()).name}
              </Link>
            )}
          />
          <select
            key={selectedToken}
            defaultValue={selectedToken}
            onChange={this.handleSelectChange}
            className={style.token_select}
          >
            {tokensNames.map(name => (
              <option
                key={name.tokenSymbol}
                value={name.tokenSymbol}
                className={style.token_select_option}
              >
                {name.tokenSymbol}
              </option>
            ))}
          </select>

        </div>
      </FeaturesContainer>
    ) : null
  }
}
TokenInfoFeaturesContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  selectToken: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  selectedToken: PropTypes.string.isRequired,
  tokensNames: PropTypes.arrayOf(PropTypes.shape({
    tokenSymbol: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
}
const mapStateToProps = state => ({
  tokensNames: state.tokensNames,
  selectedToken: state.selectedToken,
})
const mapDispatchToProps = {
  selectToken,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TokenInfoFeaturesContainer))
