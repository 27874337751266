import { SERVER_URL } from '../utils/constants'

const isTokenCreationAuthorized = async (address, tokenId, amount) => {
  const response = await fetch(
    `${SERVER_URL}/isTokenCreationAuthorized?address=${address}&tokenId=${tokenId}&amount=${amount}`
  )

  const { authorized, error } = await response.json()
  if (response.status === 500) {
    throw new Error(error)
  }

  return authorized
}
export default isTokenCreationAuthorized

