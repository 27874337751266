import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const editToken = async ({
  tokenId,
  valueInBaseToken,
  description,
  promoTermsUrl,
  logoFilename,
}, emitentId) => {
  const formData = new FormData()
  if (logoFilename) {
    formData.append('logoFilename', logoFilename)
  }
  if (valueInBaseToken) {
    formData.append('valueInBaseToken', valueInBaseToken)
  }
  if (description) {
    formData.append('description', description)
  }
  if (promoTermsUrl) {
    formData.append('promoTermsUrl', promoTermsUrl)
  }
  const response = await fetch(
    // eslint-disable-next-line max-len
    `${SERVER_URL}/editToken?emitentId=${emitentId}&tokenId=${tokenId}&authSession=${sessionLocalStore.getItem('session')}`,
    {
      method: 'PATCH',
      body: formData,
    }
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  return response.json()
}

export default editToken
