import * as types from '../action/types'

const initialState = []

const registrationReducer = (state = initialState, action) => {
  if (action.type === types.SET_REGISTRATION_ERRORS) {
    return action.errors
  }
  return state
}
export default registrationReducer
