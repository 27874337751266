import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoute = ({
  children,
  condition,
  redirect,
  location,
}) => (condition ? children : <Redirect to={{ pathname: redirect, state: { prevPath: location.pathname } }} push />)

ProtectedRoute.propTypes = {
  redirect: PropTypes.string.isRequired,
  condition: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
}

export default withRouter(ProtectedRoute)
