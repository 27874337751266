import { SERVER_URL } from '../utils/constants'

const registerEmitent = async (emitentData) => {
  const {
    username,
    userEmail,
    emitentName,
    publicKey,
  } = emitentData

  const response = await fetch(
    `${SERVER_URL}/registerEmitent`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        userEmail,
        emitentName,
        publicKey,
      }),
    }
  )
  if (response.status === 400) {
    const { errors } = await response.json()
    return { errors }
  }
  const { emitentId, emitentUserId } = await response.json()

  return ({ emitentId, emitentUserId })
}
export default registerEmitent
