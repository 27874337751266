import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import GenerateCodeSetQRCode from '../GenerateCodeSetQRCode'
import { fetchTestToken } from '../../../redux/action/index'

import style from './style.module.css'

class GenerateCodeSetFaucet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      multiscan: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount() {
    const { tokenInfo } = this.props
    if (tokenInfo.tokenId !== undefined) {
      // this.props.fetchTestToken(tokenInfo.tokenId, this.state.multiscan ? 'm' : 'i')
    }
  }

  handleInputChange(event) {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState({
      multiscan: value,
    })
  }

  render() {
    const { tokenInfo, faucet, selectedCodeset } = this.props
    const { multiscan } = this.state
    return (
      <PlainContainer
        widthInVw={80}
        customStyles={{
          // borderRadius: '8px 0px 0px 8px',
          // marginBottom: '3vh',
        }}
      >
        {faucet.qrHex.length > 0
        && <GenerateCodeSetQRCode faucet={faucet} /> }
        <form style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '1vh',
        }}
        >
          <label htmlFor="multiscan">
            Multiscan
            <input
              style={{
                WebkitAppearance: 'checkbox',
                MozAppearance: 'checkbox',
                appearance: 'checkbox',
                marginLeft: '8px',
              }}
              type="checkbox"
              name="multiscan"
              title="multiscan"
              checked={this.state.multiscan}
              onChange={this.handleInputChange}
            />
          </label>
        </form>
        <div style={{
          paddingTop: '1vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <button
            className={(tokenInfo.tokenId === undefined) ? style.token_button_disable : style.token_button}
            type="submit"
            disabled={(tokenInfo.tokenId === undefined)}
            // TODO Fix me
            /*eslint-disable */
            onClick={() => { this.props.fetchTestToken(tokenInfo.tokenId, multiscan ? 'm' : 'i', selectedCodeset.address, selectedCodeset.id) }}
          >
            Generate
          </button>
        </div>
      </PlainContainer>
    )
  }
}
GenerateCodeSetFaucet.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fetchTestToken: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // history: PropTypes.object.isRequired,
  // selectedToken: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tokenInfo: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  faucet: PropTypes.any.isRequired,
  selectedCodeset: PropTypes.any.isRequired,
  // tokensNames: PropTypes.arrayOf(PropTypes.shape({
  //   tokenSymbol: PropTypes.string,
  //   name: PropTypes.string,
  // })).isRequired,
}

const mapStateToProps = state => ({
  tokenInfo: state.tokenInfo,
  tokensNames: state.tokensNames,
  selectedToken: state.selectedToken,
  faucet: state.faucet,
  selectedCodeset: state.selectedCodeset,
})
const mapDispatchToProps = {
  fetchTestToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCodeSetFaucet)
