import * as types from '../action/types'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PARTNERS_DATA_SUCCESS:
      return action.partnersArr.map(partnerData => ({
        name: partnerData.name,
        firstReturn: partnerData.firstReturn,
        returnedTokens: partnerData.returnedTokens,
        returnedTokensVal: partnerData.returnedTokensVal,
        returnedTokensAvgValPerYear: partnerData.returnedTokensAvgValPerYear,
      }))
    default:
      return state
  }
}
