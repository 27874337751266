import * as types from '../action/types'

const initialState = ''

const selectTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_TOKEN:
      return action.selectedToken
    case types.CLEAR_SELECTED_TOKEN:
      return initialState
    default:
      return state
  }
}
export default selectTokenReducer
