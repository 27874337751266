import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'
import { uploadFile } from '../utils/upload'

const createToken = async ({
  tokenSymbol,
  name,
  activationTimestamp,
  expiryTimestamp,
  amount,
  amountPerReward,
  valueInBaseToken,
  description,
  promoTermsUrl,
  logoFile,
  refId,
  technicalDetails,
  tokenType,
  tokenReturnTime,
}, emitentId) => {
  const logoFilename = await uploadFile(logoFile)
  console.log(logoFilename)
  const payload = {
    tokenSymbol,
    name,
    activationTimestamp,
    expiryTimestamp,
    amount,
    amountPerReward,
    valueInBaseToken,
    description,
    promoTermsUrl,
    refId,
    technicalDetails,
    tokenType,
    tokenReturnTime,
    logoFilename,
  }
  const response = await fetch(
    `${SERVER_URL}/createToken?emitentId=${emitentId}&authSession=${sessionLocalStore.getItem('session')}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(payload),
    }
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  return response.json()
}

export default createToken
