import React from 'react'
import PropTypes from 'prop-types'
import ContentContainer from 'components/ContentContainer'

const GenerateCodeSetContentContainer = ({ children }) => (
  <ContentContainer top="calc(20vh - 40px)">
    {children}
  </ContentContainer>
)
GenerateCodeSetContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

}
export default GenerateCodeSetContentContainer
