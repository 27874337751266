import * as types from '../action/types'

const initialState = false

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_IN_USER:
      return true
    case types.LOG_OUT_USER:
      return false
    default:
      return state
  }
}
