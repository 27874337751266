import React from 'react'
import PropTypes from 'prop-types'

const FeaturesContainer = ({
  children,
  heightInVh,
  styleOptions = {},
}) => (
  <div style={{
    width: '100%',
    height: `${heightInVh}vh`,
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: '2vh',
    ...styleOptions,
  }}
  >
    {children}
  </div>
)
FeaturesContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  heightInVh: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,
}
FeaturesContainer.defaultProps = {
  styleOptions: {},
}
export default FeaturesContainer
