// import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const requestTestToken = async (id, t, f, cid) => {
  const response = await fetch(
    `${SERVER_URL}/requestTestToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tokenId: id,
        type: t,
        from: f,
        codeSetId: cid,
      }),
    }
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  return response.json()
}

export default requestTestToken
