import React from 'react'
import PropTypes from 'prop-types'

const LoggedUser = ({ username }) => (
  <div style={{
    position: 'absolute',
    right: '18vw',
    top: 50,
    lineHeight: '26px',
    fontSize: 'calc(0.5vw + 8px)',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'LatoLatinWebLight',
  }}
  >
    Logged in as
    {' '}
    <span style={{
      fontFamily: 'LatoLatinWebMedium',
    }}
    >
      {username}
    </span>
  </div>
)

LoggedUser.propTypes = {
  username: PropTypes.string.isRequired,
}

export default LoggedUser
