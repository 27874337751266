import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { QRCode } from 'react-qr-svg'
import PlainContainer from '../../../components/PlainContainer'

class LoginViewQRContainer extends Component {
  componentDidMount() {
    this.props.onComponentMount(this.props.history, this.props.location)
  }

  componentWillUnmount() {
    this.props.onComponentUnmount()
  }

  render() {
    return this.props.shouldQRRender && (
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          zIndex: 100,
          backgroundImage: 'linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35))',
        }}
      >
        <PlainContainer
          widthInVw={40}
          heightInVh={65}
          customStyles={{
            top: '15vh',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <>
            <h2 style={{
              fontWeight: 200,
              fontSize: 'calc(1vw + 9px)',
              color: '#4B4B4B',
            }}
            >
              Scan the code with your wallet to log in
            </h2>
            <QRCode
              bgColor="white"
              fgColor="#9d4e91"
              level="Q"
              style={{ width: '45%' }}
              value={this.props.loginQRCodeString}
            />
          </>
        </PlainContainer>
      </div>
    )
  }
}

LoginViewQRContainer.propTypes = {
  shouldQRRender: PropTypes.func.isRequired,
  onComponentMount: PropTypes.func.isRequired,
  onComponentUnmount: PropTypes.func.isRequired,
  loginQRCodeString: PropTypes.string.isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    block: PropTypes.func,
    createRef: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    length: PropTypes.number,
    listen: PropTypes.func,
    location: PropTypes.object,
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
}

export default withRouter(LoginViewQRContainer)
