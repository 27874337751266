/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, isPristine } from 'redux-form'
import {
  required, validateForm, url, numericality,
} from 'redux-form-validators'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import { SERVER_URL } from 'utils/constants'
import Spinner from 'components/Spinner'
import FormPopUp from '../FormPopUp'
import { editTokenAction } from '../../../redux/action'
import style from './style.module.css'

class TokenInfoFormContainer extends React.Component {
  state = { logoImgSrc: '' }

  componentDidMount() {
    const { tokenInfo } = this.props
    this.props.initialize({
      description: tokenInfo.description,
      promoTermsUrl: tokenInfo.promoTermsUrl,
      valueInBaseToken: `${tokenInfo.valueInBaseToken}`,
    })
  }

  componentDidUpdate(prevProps) {
    const { tokenInfo } = this.props
    if (prevProps.tokenInfo !== tokenInfo) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ logoImgSrc: '' })
      this.props.initialize({
        description: tokenInfo.description,
        promoTermsUrl: tokenInfo.promoTermsUrl,
        valueInBaseToken: `${tokenInfo.valueInBaseToken}`,
      })
    }
  }

  onSubmit = (formVal) => {
    const { tokenInfo } = this.props
    const updatedInput = {}
    // eslint-disable-next-line eqeqeq
    if (tokenInfo.valueInBaseToken != formVal.valueInBaseToken) {
      updatedInput.valueInBaseToken = formVal.valueInBaseToken
    }
    if (tokenInfo.description !== formVal.description) {
      updatedInput.description = formVal.description
    }
    if (tokenInfo.promoTermsUrl !== formVal.promoTermsUrl) {
      updatedInput.promoTermsUrl = formVal.promoTermsUrl
    }
    if (tokenInfo.logoFilename !== formVal.logoFilename && formVal.logoFilename) {
      // eslint-disable-next-line prefer-destructuring
      updatedInput.logoFilename = formVal.logoFilename[0]
    }

    this.props.editTokenAction({ ...updatedInput, tokenId: tokenInfo.tokenId }, tokenInfo.tokenSymbol)
  }

  handleFileChange = (formProp, e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    formProp.input.onChange(e)
    const fileReader = new FileReader()
    fileReader.onload = (ev) => {
      this.setState({
        logoImgSrc: ev.target.result,
      })
    }
    fileReader.readAsDataURL(file)
  }

  renderInput = formProp => (
    <div style={{
      height: '20%',
      position: 'relative',
    }}
    >
      <div style={{
        color: '#505050',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <label className={style.label} htmlFor={formProp.label}>
          {formProp.label}
        </label>
        <input
          {...formProp.input}
          id={formProp.label}
          className={style.token_input}
          type={formProp.type}
          min={1}
          autoComplete="off"
        />
        <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
      </div>
    </div>
  )

  renderTextArea = formProp => (
    <div style={{
      height: '40%',
    }}
    >
      <label className={style.label} htmlFor={formProp.label}>
        {formProp.label}
      </label>
      <textarea
        id={formProp.label}
        className={style.token_textArea}
        style={{
          color: '#505050',
          lineHeight: '1.5',
          height: '100%',

        }}
        {...formProp.input}
        autoComplete="off"
      />
      <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
    </div>

  )

  renderFileInput = (formProp) => {
    // eslint-disable-next-line no-param-reassign
    delete formProp.input.value
    return (
      <div style={{
        gridColumn: `${formProp.gridColumn}`,
        gridRow: `${formProp.gridRow}`,
        position: 'relative',
        width: '100%',
      }}
      >
        <label htmlFor={formProp.label}>
          <img
            style={{
              cursor: 'pointer',
              borderRadius: '50%',
              width: '18vh',
              height: '18vh',
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translate(-50%,-10%)',
              boxShadow: '11px 9px 18px -6px rgba(140,140,140,1)',
            }}
            src={this.state.logoImgSrc || `${SERVER_URL}/getImage?imagePath=${this.props.tokenInfo.logoFilename}`}
            alt="tokens logo"
          />
        </label>
        <input
          id={formProp.label}
          {...formProp.input}
          onChange={value => this.handleFileChange(formProp, value)}
          onBlur={() => { /* left empty to fix redux-form overrides */ }}
          style={{
            position: 'absolute',
            left: '-10000px',
            top: '-10000px',
          }}
          type="file"
        />
      </div>
    )
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>
    }
    return null
  }

  render() {
    const { tokenInfo, editedToken, isTokenBeingUpdated } = this.props
    return (
      <>
        <PlainContainer
          customStyles={{
            borderRadius: '8px 0 0 8px',
            width: '100%',
            height: '100%',
            gridColumn: '1/2',
            gridRow: '1/2',
            paddingBottom: 0,
            position: 'relative',
          }}
        >
          {editedToken.hasGotResponse && <FormPopUp error={editedToken.error} />}
          {isTokenBeingUpdated ? <Spinner /> : (
            <form
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              style={{
                fontSize: 'calc(0.5vw + 8px)',
                letterSpacing: '0.3px',
                display: 'grid',
                width: '100%',
                height: '100%',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '75% 25%',
                padding: '30px 20px',
              }}
            >
              <Field
                name="logoFilename"
                label="file"
                component={this.renderFileInput}
                value={null}
                accept=".jpg, .png, .jpeg, .svg, .gif, .webp"
                gridColumn="1/2"
                gridRow="1/2"
                key={this.state.logoImgSrc || tokenInfo.logoFilename}
              />
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexFlow: 'column nowrap',
                  justifyContent: 'space-between',
                  gridColumn: '2/3',
                  gridRow: '1/2',
                }}
              >
                <Field
                  name="description"
                  component={this.renderTextArea}
                  label="description"
                  gridColumn="1/3"
                  gridRow="3/5"
                  key={tokenInfo.description}
                />
                <Field
                  name="promoTermsUrl"
                  component={this.renderInput}
                  label="link to the terms of the promotion"
                  type="url"
                  gridColumn="3/4"
                  gridRow="1/2"
                />
                <Field
                  name="valueInBaseToken"
                  component={this.renderInput}
                  label="value"
                  type="number"
                  gridColumn="2/3"
                  gridRow="2/3"
                />
              </div>
              <div style={{
                gridColumn: '1/3',
                gridRow: '2/3',
                position: 'relative',
              }}
              >
                <p
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '10%',
                    width: '100%',
                    textAlign: 'center',
                    transform: 'translateX(-50%)',
                  }}
                  className={style.label}
                >
                  Click on the element to edit
                </p>
                <button
                  className={this.props.isPristine || this.props.invalid
                    ? style.token_button_disable : style.token_button}
                  disabled={!!(this.props.isPristine || this.props.invalid)}
                  style={{
                    bottom: '0',
                  }}
                  type="submit"
                >
                  Edit Token
                </button>
              </div>
            </form>
          )}

        </PlainContainer>
      </>
    )
  }
}

TokenInfoFormContainer.propTypes = {
  editTokenAction: PropTypes.func.isRequired,
  setInitialEditTokenResponse: PropTypes.func.isRequired,
  editedToken: PropTypes.shape({
    hasGotResponse: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  isTokenBeingUpdated: PropTypes.bool.isRequired,
  tokenInfo: PropTypes.shape({
    tokenId: PropTypes.number.isRequired,
    name: PropTypes.string,
    tokenSymbol: PropTypes.string,
    logoFilename: PropTypes.string,
    emittedTokens: PropTypes.number,
    generatedTokens: PropTypes.number,
    tokensInActiveUnscannedCodes: PropTypes.number,
    tokensInCircl: PropTypes.number,
    scannedTokens: PropTypes.number,
    tokensReturned: PropTypes.number,
    tokensOnIssuerAccount: PropTypes.number,
    tokensAvailableForCodes: PropTypes.number,
    emitentId: PropTypes.number,
    activationTimestamp: PropTypes.string,
    amountPerReward: PropTypes.number,
    valueInBaseToken: PropTypes.number,
    description: PropTypes.string,
    promoTermsUrl: PropTypes.string,
    expiryTimestamp: PropTypes.string,
  }).isRequired,
  // redux form properties, docs: https://redux-form.com/7.4.2/docs/api/props.md/
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
}

const validate = validateForm({
  valueInBaseToken: [required(), numericality({
    '>=': 1,
    msg: { '>=': 'Value must be 1 or greater' },
  })],
  description: required(),
  promoTermsUrl: [required(), url()],
  logoFile: [required()],
})

const mapDispatchToProps = {
  editTokenAction,
}
const mapStateToProps = state => ({
  tokenInfo: state.tokenInfo,
  isPristine: isPristine('updateToken')(state),
  editedToken: state.editedToken,
  isTokenBeingUpdated: state.isTokenBeingUpdated,
})
const formWrapper = reduxForm({
  form: 'updateToken',
  validate,
})(TokenInfoFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(formWrapper)
