import React from 'react'
import FourOFourBackground from './FourOFourBackground'
import FourOFourContent from './FourOFourContent'

export default () => (
  <>
    <FourOFourBackground />
    <FourOFourContent />
  </>
)

