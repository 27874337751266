import { connect } from 'react-redux'
import LoginViewQRContainer from './LoginViewQRContainer'
import { generateSessionId } from '../../../utils/generateSessionId'
import { createBackgroundProcess } from '../../../utils/async'
import {
  setSessionId, logInUser, setEmitentId, setUsername,
} from '../../../redux/action/index'
import { getLoginQRCodeFromSessionId } from '../../../utils/getLoginQRCodeFromSessionId'
import loginEmitent from '../../../api/loginEmitent'
import { CHECK_IF_AUTHORIZED_SESSION_OBJECT_IS_AVAILABLE_INTERVAL } from '../../../utils/constants'
import { sessionLocalStore } from '../../../utils/sessionLocalStore'

const redirectOnLogin = (history, location) => {
  if (!location.state) {
    history.replace(`${process.env.PUBLIC_URL}/tokens`)
  } else {
    history.goBack()
  }
}

const tryToLoginBackgroundProcess = createBackgroundProcess(
  CHECK_IF_AUTHORIZED_SESSION_OBJECT_IS_AVAILABLE_INTERVAL, async (checkIfShouldInterrupt,
    sessionId,
    dispatch,
    history,
    location,
  ) => {
    const loginResponse = await loginEmitent(sessionId)
    if (checkIfShouldInterrupt()) {
      return
    }
    if (loginResponse.success) {
      const { session, emitentUsersList, username } = loginResponse
      sessionLocalStore.setItem('session', JSON.stringify(session))
      dispatch(logInUser())
      if (!emitentUsersList.length) {
        history.push(`${process.env.PUBLIC_URL}/register`)
      } else {
        dispatch(setEmitentId(emitentUsersList[0].emitent_id))
        dispatch(setUsername(username))
        sessionLocalStore.setItem('emitentUserId', emitentUsersList[0].emitent_user_id)
        sessionLocalStore.setItem('emitentId', emitentUsersList[0].emitent_id)
        sessionLocalStore.setItem('username', username)
        redirectOnLogin(history, location)
      }
    }
  }
)

const onComponentMount = async (dispatch, history, location) => {
  if (!sessionLocalStore.getItem('session')) {
    const sessionId = generateSessionId()
    dispatch(setSessionId(sessionId))
    tryToLoginBackgroundProcess.start(sessionId, dispatch, history, location)
  } else {
    dispatch(setEmitentId(parseInt(sessionLocalStore.getItem('emitentId'), 10)))
    dispatch(setUsername(sessionLocalStore.getItem('username')))
    dispatch(logInUser())
    redirectOnLogin(history, location)
  }
}

const onComponentUnmount = () => tryToLoginBackgroundProcess.stop()

const mapStateToProps = state => ({
  loginQRCodeString: getLoginQRCodeFromSessionId(state.sessionId),
})

const mapActionsToProps = dispatch => ({
  onComponentMount: (history, location) => onComponentMount(dispatch, history, location),
  onComponentUnmount,
  shouldQRRender: !sessionLocalStore.getItem('session'),
})

export default connect(mapStateToProps, mapActionsToProps)(LoginViewQRContainer)
