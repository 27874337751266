export const sleep = delay => new Promise(res => setTimeout(res, delay))

export function createBackgroundProcess(interval, action) {
  let isRunning = false
  const checkIfShouldInterrupt = () => !isRunning

  return {
    async start(...args) {
      isRunning = true
      while (isRunning === true) {
        /* eslint-disable no-await-in-loop */
        await action(checkIfShouldInterrupt, ...args)
        await sleep(interval)
      }
    },
    stop() {
      isRunning = false
    },
  }
}
