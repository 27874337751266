import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyDLuM62E6cC-wfKw1X5p1MwCyJVhqePMe0',
  authDomain: 'prod-dhunter.firebaseapp.com',
  databaseURL: 'https://prod-dhunter.firebaseio.com',
  projectId: 'prod-dhunter',
  storageBucket: 'gs://prod-dhunter-images',
  messagingSenderId: '1087660032037',
  appId: '1:1087660032037:web:7e442ea8a09eb4f4747fcc',
}
firebase.initializeApp(firebaseConfig)
const storage = firebase.storage()
export default storage
