import * as types from '../action/types'

const initialState = []

export const tokensListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKENS_LIST_SUCCESS:
      return action.tokens.map(token => ({
        emitentId: token.emitentId,
        activationTimestamp: token.activationTimestamp,
        amountPerReward: token.amountPerReward,
        valueInBaseToken: token.valueInBaseToken,
        description: token.description,
        promoTermsUrl: token.promoTermsUrl,
        logoFilename: token.logoFilename,
        tokenSymbol: token.tokenSymbol,
        name: token.name,
        amount: token.amount,
        tokensInCirculation: token.tokensInCirculation,
        generatedTokens: token.generatedTokens,
        expiryTimestamp: token.expiryTimestamp,
        tokenId: token.tokenId,
      }))
    default:
      return state
  }
}
export const tokenInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN_INFO_SUCCESS:
      return {
        tokenId: action.tokenInfo.tokenId,
        name: action.tokenInfo.name,
        tokenSymbol: action.tokenInfo.tokenSymbol,
        logoFilename: action.tokenInfo.logoFilename,
        emittedTokens: action.tokenInfo.emittedTokens,
        generatedTokens: action.tokenInfo.generatedTokens,
        tokensInActiveUnscannedCodes: action.tokenInfo.tokensInActiveUnscannedCodes,
        tokensInCircl: action.tokenInfo.tokensInCircl,
        scannedTokens: action.tokenInfo.scannedTokens,
        tokensReturned: action.tokenInfo.tokensReturned,
        tokensOnIssuerAccount: action.tokenInfo.tokensOnIssuerAccount,
        tokensAvailableForCodes: action.tokenInfo.tokensAvailableForCodes,
        emitentId: action.tokenInfo.emitentId,
        activationTimestamp: action.tokenInfo.activationTimestamp,
        amountPerReward: action.tokenInfo.amountPerReward,
        valueInBaseToken: action.tokenInfo.valueInBaseToken,
        description: action.tokenInfo.description,
        promoTermsUrl: action.tokenInfo.promoTermsUrl,
        expiryTimestamp: action.tokenInfo.expiryTimestamp,
      }
    case types.SET_INITIAL_TOKEN_INFO:
      return initialState

    default:
      return state
  }
}

export const tokensNamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKENS_NAMES_SUCCESS:
      return action.names.map(nameObj => ({
        name: nameObj.name,
        tokenSymbol: nameObj.tokenSymbol,
      }))
    case types.CLEAR_TOKENS_NAMES:
      return initialState
    default:
      return state
  }
}

export const createTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_NEW_TOKEN_SUCCESS:
      return {
        error: action.newToken.error,
        createdTokenId: action.newToken.createdTokenId,
      }
    case types.CLEAR_NEW_TOKEN_FORM:
      return {}
    default:
      return state
  }
}
export const editTokenReducer = (state = { error: null, hasGotResponse: false }, action) => {
  switch (action.type) {
    case types.SET_EDITED_TOKEN_RESPONSE:
      return {
        error: action.error,
        hasGotResponse: action.hasGotResponse,
      }
    case types.SET_INITIAL_TOKEN_EDIT_RESPONSE:
      return {
        error: null,
        hasGotResponse: false,
      }
    default:
      return state
  }
}
