import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlainContainer from 'components/PlainContainer'
import CreateCodeSetViewQRCode from '../CreateCodeSetViewQRCode'
import { formatNumber } from '../../../helperFunctions'
import {
  setCodeSetOutputFilename, checkIfCodeSetIsToppedUp, clearNewCodeSet, clearIsCodeSetToppedUp,
} from '../../../redux/action'
import {
  CHECK_IF_CODESET_CREATION_TX_IS_IN_BALANCES_INTERVAL,
  SET_CODE_SET_OUTPUT_FILENAME_INTERVAL,
} from '../../../utils/constants'
import deleteCodeSet from '../../../api/deleteCodeSet'
import { createBackgroundProcess } from '../../../utils/async'
import cross from '../../../icons/cross.svg'
import style from './style.module.css'

class CreateCodeSetFinal extends React.Component {
  constructor(props) {
    super(props)
    this.createCodeSetBalanceProcess = createBackgroundProcess(
      CHECK_IF_CODESET_CREATION_TX_IS_IN_BALANCES_INTERVAL,
      props.checkIfCodeSetIsToppedUp
    )
    this.isCodeSetReadyToDownloadProcess = createBackgroundProcess(
      SET_CODE_SET_OUTPUT_FILENAME_INTERVAL,
      props.setCodeSetOutputFilename,
    )
  }

  componentDidMount() {
    const { tokenSymbol, amount, address } = this.props
    this.createCodeSetBalanceProcess.start(
      address,
      tokenSymbol,
      amount
    )
  }

  componentDidUpdate() {
    const { isCodeSetToppedUp } = this.props
    if (isCodeSetToppedUp.toppedUp) {
      this.createCodeSetBalanceProcess.stop()
    }
  }

  componentWillUnmount() {
    const { isCodeSetToppedUp } = this.props
    this.createCodeSetBalanceProcess.stop()
    this.props.clearNewCodeSet()
    this.props.clearIsCodeSetToppedUp()
    if (isCodeSetToppedUp.toppedUp) {
      this.isCodeSetReadyToDownloadProcess.start(this.props.address, this.isCodeSetReadyToDownloadProcess.stop)
    } else {
      deleteCodeSet(this.props.address)
    }
  }

  redirect = () => {
    const { hideModal, isCodeSetToppedUp } = this.props
    if (isCodeSetToppedUp.toppedUp) {
      this.props.history.push(`${process.env.PUBLIC_URL}/codesets`)
    } else {
      hideModal()
    }
  }

  render() {
    const {
      amount, tokenId, tokenSymbol, address, isCodeSetToppedUp,
    } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={this.redirect}
        className={style.modal_background}
      >
        <PlainContainer
          heightInVh={60}
          widthInVw={30}
          customStyles={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',

          }}
        >
          <div
            onClick={(e) => { e.stopPropagation() }}
            role="button"
            tabIndex={0}
            className={style.modal}
          >
            <button
              type="button"
              onClick={this.redirect}
            >
              <img
                className={style.close}
                src={cross}
                alt="close window"
              />
            </button>
            {isCodeSetToppedUp.toppedUp ? (
              <h2 className={style.notification}>
                The Code Set has been successfully created and topped up.
              </h2>
            )
              : (
                <>
                  <h2 className={style.txt_first}>
                    Supplying the code list
                  </h2>
                  <p className={style.txt_content}>
                    Transfer
                    {' '}
                    {formatNumber(amount)}
                    {' '}
                    {tokenSymbol.toUpperCase()}
                    {' '}
                    to the address provided to top up the codes
                  </p>
                  <CreateCodeSetViewQRCode
                    txPayloadString={`r${JSON.stringify({
                      address, tokenId, token_symbol: tokenSymbol, amount,
                    })}`}
                  />
                </>
              )
            }
          </div>
        </PlainContainer>
      </div>
    )
  }
}

CreateCodeSetFinal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  tokenSymbol: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  tokenId: PropTypes.number.isRequired,
  isCodeSetToppedUp: PropTypes.shape({
    error: PropTypes.string,
    toppedUp: PropTypes.bool,
  }).isRequired,
  checkIfCodeSetIsToppedUp: PropTypes.func.isRequired,
  clearNewCodeSet: PropTypes.func.isRequired,
  clearIsCodeSetToppedUp: PropTypes.func.isRequired,
  setCodeSetOutputFilename: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({ isCodeSetToppedUp: state.isCodeSetToppedUp })
const mapDispatchToProps = {
  checkIfCodeSetIsToppedUp,
  clearNewCodeSet,
  clearIsCodeSetToppedUp,
  setCodeSetOutputFilename,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCodeSetFinal))
