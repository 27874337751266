import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DHunterLogo from './DHunterLogo'
import TopBarNavigation from './TopBarNavigation'
import LoggedUser from './LoggedUser'
import TopBarButtonsPanel from './TopBarButtonsPanel'

const NavTopBar = ({ username, history }) => {
  // eslint-disable-next-line prefer-destructuring
  const pathname = history.location.pathname
  return (
    <>
      <DHunterLogo />
      <TopBarNavigation location={pathname} />
      <LoggedUser username={username} />
      <TopBarButtonsPanel />
    </>
  )
}
NavTopBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object,
    pathname: PropTypes.string,
  }).isRequired,
  username: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  username: state.username,
})

export default withRouter(connect(mapStateToProps)(NavTopBar))
