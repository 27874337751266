import React from 'react'
import Background from '../../components/Background'
import RegisterEmitentFormContainer from './RegisterEmitentFormContainer'
import RegisterEmitentContentContainer from './RegisterEmitentContentContainer'

const RegisterView = () => (
  <React.Fragment>
    <Background height="30vh" />
    <RegisterEmitentContentContainer>
      <RegisterEmitentFormContainer />
    </RegisterEmitentContentContainer>
  </React.Fragment>
)

export default RegisterView
