import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TableContainer from 'components/TableContainer'
import TableEntry from 'components/TableEntry'
// import checkedBox from 'icons/checkedBox.svg'
// import emptyBox from 'icons/emptyBox.svg'
//  import { SERVER_URL } from 'utils/constants'
import { fetchCodeSetList, selectCodeset } from '../../../redux/action'
import { formatNumber } from '../../../helperFunctions'
import Loadable from '../../../components/Loadable'
// import { sessionLocalStore } from '../../../utils/sessionLocalStore'

class CodeSetsList extends React.Component {
  componentDidMount() {
    this.props.fetchCodeSetList()
  }

  render() {
    const { codeSetList } = this.props
    return (
      <Loadable>
        <TableContainer
          columnWidths={[
            '15%',
            '10%',
            '17%',
            '12%',
            '16%',
            '10%',
            // '12%',
          ]}
          columnNames={[
            'date',
            'token',
            'name of set',
            'tokens per code',
            'tokens together',
            'codes',
            'QR',
            // 'download',
          ]}
          heightInVh={69}
        >
          {
            columnWidths => (
              codeSetList.map(codeSet => (
                <TableEntry
                  columnWidths={columnWidths}
                  rowLabels={[
                    // codeSet.activationTimestamp.slice(0, codeSet.activationTimestamp.indexOf('T')),
                    codeSet.activationTimestamp,
                    codeSet.tokenSymbol.toUpperCase(),
                    codeSet.name,
                    formatNumber(codeSet.tokensPerCode),
                    formatNumber(codeSet.tokensAmount),
                    formatNumber(codeSet.codes),
                    // <img
                    //   style={{
                    //     transform: 'scale(0.8)',
                    //   }}
                    //   src={codeSet.isToppedUp ? checkedBox : emptyBox}
                    //   alt="check box"
                    // />,
                    <Link
                      to={`/codesets/generate/${codeSet.tokenSymbol}`}
                      onClick={() => this.props.selectCodeset(codeSet)}
                    >
                      Generate
                    </Link>,
                    // codeSet.outputFilename
                    //   ? (
                    //     <a
                    //       rel="noopener noreferrer"
                    //       target="_blank"
                    //       // eslint-disable-next-line max-len
                    //      href={`${SERVER_URL}/getCodeSetFile?outputFilename=${codeSet.outputFilename}&authSession=
                    //      ${sessionLocalStore.getItem('session')}`}
                    //     >
                    //     Download
                    //     </a>
                    //   )
                    //   : 'Generating...',
                  ]}

                  key={codeSet.name}
                />
              ))
            )
          }
        </TableContainer>
      </Loadable>
    )
  }
}
CodeSetsList.propTypes = {
  fetchCodeSetList: PropTypes.func.isRequired,
  selectCodeset: PropTypes.func.isRequired,
  codeSetList: PropTypes.arrayOf(PropTypes.object).isRequired,
}
const mapStateToProps = state => ({
  codeSetList: state.codeSetList,
})
const mapDispatchToProps = {
  fetchCodeSetList,
  selectCodeset,
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeSetsList)
