import React from 'react'
import LoginViewQRContainer from './LoginViewQRContainer'
import LoginViewBackground from './LoginViewBackground'

const LoginView = () => (
  <React.Fragment>
    <LoginViewBackground />
    <LoginViewQRContainer />
  </React.Fragment>
)

export default LoginView
