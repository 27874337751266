import * as types from '../action/types'

const initialState = {
  qrHex: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_QR_HEX:
      return action.data
    default:
      return state
  }
}
