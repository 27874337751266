import React from 'react'
import PropTypes from 'prop-types'

const ScrollableTableEntry = ({
  columnWidths,
  rowLabels,
  onClick,
  badge = null,
  styleOptions,
}) => {
  // eslint-disable-next-line react/no-array-index-key
  const rowList = rowLabels.map((label, index) => <div key={index} style={{ width: columnWidths[index] }}>{label}</div>)
  return (
    <div role="button" tabIndex={0} onClick={onClick}>
      <div style={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        width: '100%',
        height: '8vh',
        marginBottom: '8px',
        display: 'flex',
        borderRadius: '4px',
        alignItems: 'center',
        backgroundColor: 'white',
        justifyContent: 'space-around',
        fontSize: 'calc(0.5vw + 8px)',
        letterSpacing: '0.3px',
        textAlign: 'center',
        color: '#4F4D4E',
        ...styleOptions,
      }}
      >
        {badge && <div>{badge}</div>}
        {rowList}
      </div>
      <div style={{
        width: '80%',
      }}
      />
    </div>
  )
}
ScrollableTableEntry.propTypes = {
  columnWidths: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowLabels: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClick: PropTypes.func,
  badge: PropTypes.element,
  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,
}
ScrollableTableEntry.defaultProps = {
  onClick: () => null,
  badge: null,
  styleOptions: {},
}
export default ScrollableTableEntry
