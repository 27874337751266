export const SET_PARTNERS_DATA_SUCCESS = 'SET_PARTNERS_DATA_SUCCESS'
export const SET_TOKENS_LIST_SUCCESS = 'SET_TOKENS_LIST_SUCCESS'
export const SET_TOKENS_NAMES_SUCCESS = 'SET_TOKENS_NAMES_SUCCESS'
export const CLEAR_TOKENS_NAMES = 'CLEAR_TOKENS_NAMES'
export const SET_TOKEN_INFO_SUCCESS = 'SET_TOKEN_INFO_SUCCESS'
export const SET_INITIAL_TOKEN_INFO = 'SET_INITIAL_TOKEN_INFO'
export const SET_INITIAL_TOKEN_EDIT_RESPONSE = 'SET_INITIAL_TOKEN_EDIT_RESPONSE'
export const SELECT_TOKEN = 'SELECT_TOKEN'
export const SELECT_CODESET = 'SELECT_CODESET'
export const CLEAR_SELECTED_CODESET = 'SELECT_CODESET'
export const CLEAR_SELECTED_TOKEN = 'CLEAR_SELECTED_TOKEN'
export const FETCH_CODE_SET_LIST = 'FETCH_CODE_SET_LIST'
export const CREATE_NEW_TOKEN = 'CREATE_NEW_TOKEN'
export const CREATE_NEW_CODE_SET = 'CREATE_NEW_CODE_SET'
export const SET_CODE_SET_LIST_SUCCESS = 'SET_CODE_SET_LIST_SUCCESS'
export const CREATE_NEW_TOKEN_SUCCESS = 'CREATE_NEW_TOKEN_SUCCESS'
export const CLEAR_NEW_TOKEN_TRANSACTION_INFO = 'CLEAR_NEW_TOKEN_TRANSACTION_INFO'
export const SET_NEW_TOKEN_TRANSACTION_INFO = 'SET_NEW_TOKEN_TRANSACTION_INFO'
export const SET_CODE_SET_TX_INFO = 'SET_CODE_SET_TX_INFO'
export const SET_SESSION_ID = 'SET_SESSION_ID'
export const LOG_IN_USER = 'LOG_IN_USER'
export const SET_NEW_TOKEN_AUTH_STATE = 'SET_NEW_TOKEN_AUTH_STATE'
export const LOG_OUT_USER = 'LOG_OUT_USER'
export const SET_EMITENT_ID = 'SET_EMITENT'
export const SET_REGISTRATION_ERRORS = 'SET_REGISTRATION_ERRORS'
export const SET_USERNAME = 'SET_USERNAME'
export const CLEAR_NEW_CODE_SET = 'CLEAR_NEW_CODE_SET'
export const CLEAR_IS_CODE_SET_TOPPED_UP = 'CLEAR_IS_CODE_SET_TOPPED_UP'
export const CLEAR_CODE_SET_ERROR = 'CLEAR_CODE_SET_ERROR'
export const SET_CODE_SET_OUTPUT_FILE = 'SET_CODE_SET_OUTPUT_FILE'
export const BEGIN_API_CALL = 'BEGIN_API_CALL'
export const BEGIN_UPDATE_TOKEN_API_CALL = 'BEGIN_UPDATE_TOKEN_API_CALL'
export const END_API_CALL = 'END_API_CALL'
export const END_UPDATE_TOKEN_API_CALL = 'END_UPDATE_TOKEN_API_CALL'
export const SET_EDITED_TOKEN_RESPONSE = 'SET_EDITED_TOKEN_RESPONSE'
export const CLEAR_NEW_TOKEN_FORM = 'CLEAR_NEW_TOKEN_FORM'
export const SET_QR_HEX = 'SET_QR_HEX'
export const SET_AUDIOBOOK_DATA_SUCCESS = 'SET_AUDIOBOOK_DATA_SUCCESS'
export const RESET_AUDIOBOOK_DATA = 'RESET_AUDIOBOOK_DATA'
