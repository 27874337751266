import React from 'react'
import ContentContainer from 'components/ContentContainer'
import PropTypes from 'prop-types'

const TokensContentContainer = ({ children }) => (
  <ContentContainer top="calc(22vh - 40px)">
    {children}
  </ContentContainer>
)
TokensContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

}
export default TokensContentContainer

