/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import { formatNumber } from '../../../helperFunctions'
import style from './style.module.css'

const TokenInfoNumbDataContainer = ({ tokenInfo }) => (
  <PlainContainer
    customStyles={{
      borderRadius: '0px',
      width: '100%',
      height: '100%',
      gridColumn: '2/3',
      gridRow: '1/2',
    }}
  >
    {tokenInfo.name ? (
      <div className={style.tokenInfo_data}>
        <div>
          <span>Total tokens</span>
          <span>{formatNumber(tokenInfo.emittedTokens)}</span>
        </div>
        <div>
          <span>Total tokens in codes</span>
          <span>{formatNumber(tokenInfo.generatedTokens)}</span>
        </div>
        <div>
          <span>Tokens in active unscanned codes</span>
          <span>{formatNumber(tokenInfo.tokensInActiveUnscannedCodes)}</span>
        </div>
        <div>
          <span>Tokens in circulation</span>
          <span>{formatNumber(tokenInfo.tokensInCircl)}</span>
        </div>
        <div>
          <span>Scanned tokens</span>
          <span>{formatNumber(tokenInfo.scannedTokens)}</span>
        </div>
        {/* <div>
          <span>Returned tokens</span>
          <span>{formatNumber(tokenInfo.tokensReturned)}</span>
        </div> */}
        <div>
          <span>Tokens on issuer account</span>
          <span>{formatNumber(tokenInfo.tokensOnIssuerAccount)}</span>
        </div>
        {/* <div>
          <span>Tokens available for codes</span>
          <span>{formatNumber(tokenInfo.tokensAvailableForCodes)}</span>
        </div> */}
      </div>
    ) : null
    }

  </PlainContainer>
)
TokenInfoNumbDataContainer.propTypes = {
  tokenInfo: PropTypes.shape({
    name: PropTypes.string,
    tokenSymbol: PropTypes.string,
    logoFilename: PropTypes.string,
    emittedTokens: PropTypes.number,
    generatedTokens: PropTypes.number,
    tokensInActiveUnscannedCodes: PropTypes.number,
    tokensInCircl: PropTypes.number,
    tokensInCodes: PropTypes.number,
    scannedTokens: PropTypes.number,
    tokensReturned: PropTypes.number,
    tokensOnIssuerAccount: PropTypes.number,
    tokensAvailableForCodes: PropTypes.number,
    emitentId: PropTypes.number,
    activationTimestamp: PropTypes.string,
    amountPerReward: PropTypes.number,
    valueInBaseToken: PropTypes.number,
    description: PropTypes.string,
    promoTermsUrl: PropTypes.string,
    expiryTimestamp: PropTypes.string,
  }).isRequired,
}
const mapStateToProps = state => ({ tokenInfo: state.tokenInfo })

export default connect(mapStateToProps)(TokenInfoNumbDataContainer)
