/* eslint-disable max-len */
import React from 'react'
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LogoAndHeaderTopBar from './components/LogoAndHeaderTopBar'
import PartnersView from './views/PartnersView'
import TokensView from './views/TokensView'
import CodeSetsView from './views/CodeSetsView'
import TransactionsView from './views/TransactionsView'
import CreateTokenView from './views/CreateTokenView'
import CreateCodeSetView from './views/CreateCodeSetView'
import TokenInfoView from './views/TokenInfoView'
import LoginView from './views/LoginView'
import FourOFour from './views/FourOFour'
import RegisterEmitentView from './views/RegisterEmitentView'
import ProtectedRoute from './components/ProtectedRoute'
import NavTopBar from './components/NavTopBar'
import GenerateCodeSetView from './views/GenerateCodeSetView'

const Router = ({ doesUserHaveEmitentId, loggedIn }) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/login`}
        exact
        render={() => (
          <React.Fragment>
            <LogoAndHeaderTopBar />
            <LoginView />
          </React.Fragment>
        )}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/register`}
        exact
        render={() => (!doesUserHaveEmitentId && loggedIn ? (
          <React.Fragment>
            <LogoAndHeaderTopBar />
            <RegisterEmitentView />
          </React.Fragment>
        )
          : <Redirect to={`${process.env.PUBLIC_URL}/`} />)
        }
      />
      <ProtectedRoute
        condition={loggedIn && doesUserHaveEmitentId}
        redirect={!loggedIn ? `${process.env.PUBLIC_URL}/login` : `${process.env.PUBLIC_URL}/register`}
      >
        <NavTopBar />
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/`}
            exact
            render={() => <Redirect to={`${process.env.PUBLIC_URL}/tokens`} />}
          />
          <Route path={`${process.env.PUBLIC_URL}/tokens`} exact component={TokensView} />
          <Route path={`${process.env.PUBLIC_URL}/tokens/info/:tokenSymbol`} exact component={TokenInfoView} />
          <Route path={`${process.env.PUBLIC_URL}/partners`} exact component={PartnersView} />
          <Route path={`${process.env.PUBLIC_URL}/tokens/create`} exact component={CreateTokenView} />
          <Route path={`${process.env.PUBLIC_URL}/codesets`} exact component={CodeSetsView} />
          <Route path={`${process.env.PUBLIC_URL}/codesets/create`} exact component={CreateCodeSetView} />
          <Route path={`${process.env.PUBLIC_URL}/codesets/generate/:tokenSymbol`} exact component={GenerateCodeSetView} />
          <Route path={`${process.env.PUBLIC_URL}/transactions`} exact component={TransactionsView} />
          <Route component={FourOFour} />
        </Switch>
      </ProtectedRoute>
    </Switch>
  </BrowserRouter>
)

const mapStateToProps = state => ({
  loggedIn: state.userLogin,
  doesUserHaveEmitentId: state.emitentId,
  isUserAuthorzed: state.authorizedStatus,
})

Router.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  doesUserHaveEmitentId: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps)(Router)
