import React from 'react'
import PropTypes from 'prop-types'

const TableDescription = ({ description, styleOptions = {} }) => (
  <div
    style={{
      color: 'white',
      fontSize: 'calc(0.5vw + 12px)',
      letterSpacing: '0.3px',
      marginBottom: '20px',
      ...styleOptions,
    }}
  >
    {description}
  </div>
)
TableDescription.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  styleOptions: PropTypes.object,

}
TableDescription.defaultProps = {
  styleOptions: {},
}
export default TableDescription
