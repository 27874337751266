import React from 'react'
import { Link } from 'react-router-dom'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'
import Button from 'components/Button'
import ButtonPlus from 'icons/plus.svg'
import TokensCounter from '../TokensCounter'

const TokensFeaturesContainer = () => (
  <FeaturesContainer heightInVh={10}>
    <TableDescription description="List of all Tokens" />
    <TableDescription
      description={(
        <>
          Amount of tokens types
          <TokensCounter />
        </>
      )}
      styleOptions={{
        fontSize: 'calc(0.5vw + 5px)',
        position: 'absolute',
        top: '8vh',

      }}
    />
    <Link to={`${process.env.PUBLIC_URL}/tokens/create`}>
      <Button
        text={(
          <>
            Create new token
            <img
              style={{
                width: '1em',
                height: '1em',
                marginLeft: '10px',
              }}
              src={ButtonPlus}
              alt="plus icon"
            />
          </>
        )}
        right="0px"
      />
    </Link>
  </FeaturesContainer>
)
export default TokensFeaturesContainer
