import { reset } from 'redux-form'
import getCodeSetOutputFilename from '../../api/getCodeSetOutputFilename'
import getPartnersData from '../../api/getPartnersData'
import getTokensList from '../../api/getTokensList'
import getTokenInfo from '../../api/getTokenInfo'
import getTokensNames from '../../api/getTokensNames'
import getCodeSetList from '../../api/getCodeSetList'
import createNewCodeSet from '../../api/createNewCodeSet'
import isCodeSetToppedUp from '../../api/isCodeSetToppedUp'
import registerEmitent from '../../api/registerEmitent'
import createToken from '../../api/createToken'
import isTokenCreationAuthorized from '../../api/isTokenCreationAuthorized'
import editToken from '../../api/editToken'
import * as types from './types'
import { getState } from '../../store'
import { logOut } from '../../utils/logOut'
import { UNAUTHORIZED_FETCH_ERROR } from '../../utils/constants'
import { sessionLocalStore } from '../../utils/sessionLocalStore'
import { mapNewTokenErrorToMessage } from '../../utils/mapErrorsToMessage'
import requestTestToken from '../../api/requestTestToken'
import getAudiobook from '../../api/getAudiobook'

export const beginApiCallAction = () => ({
  type: types.BEGIN_API_CALL,
})
export const beginUpdateTokenApiCallAction = () => ({
  type: types.BEGIN_UPDATE_TOKEN_API_CALL,
})
export const endApiCallAction = () => ({
  type: types.END_API_CALL,
})
export const endUpdateTokenApiCallAction = () => ({
  type: types.END_UPDATE_TOKEN_API_CALL,
})

export const setEmitentId = emitentId => ({
  type: types.SET_EMITENT_ID,
  emitentId,
})

export const setUsername = username => ({
  type: types.SET_USERNAME,
  username,
})

export const logInUser = () => ({
  type: types.LOG_IN_USER,
})

export const setSessionId = sessionId => ({
  type: types.SET_SESSION_ID,
  sessionId,
})

export const logOutUser = () => ({
  type: types.LOG_OUT_USER,
})

export const setRegistrationErrors = errors => ({
  type: types.SET_REGISTRATION_ERRORS,
  errors,
})
export const fetchPartnersDataSuccess = partnersArr => ({
  type: types.SET_PARTNERS_DATA_SUCCESS,
  partnersArr,
})
export const fetchAudiobookDataSuccess = audiobooksData => ({
  type: types.SET_AUDIOBOOK_DATA_SUCCESS,
  audiobooksData,
})
export const resetAudiobookDataInForm = () => ({
  type: types.RESET_AUDIOBOOK_DATA,
})
export const fetchPartnersData = () => async (dispatch) => {
  const { emitentId } = getState()
  dispatch(beginApiCallAction())
  try {
    const result = await getPartnersData(emitentId)
    const partnersArr = result.map(partnerData => ({
      name: partnerData.name,
      firstReturn: partnerData.firstReturn,
      returnedTokens: partnerData.returnedTokens,
      returnedTokensVal: partnerData.returnedTokensVal,
      returnedTokensAvgValPerYear: partnerData.returnedTokensAvgValPerYear,
    }))
    dispatch(fetchPartnersDataSuccess(partnersArr))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}
export const fetchTokensListSuccess = tokensArr => ({
  type: types.SET_TOKENS_LIST_SUCCESS,
  tokens: tokensArr,
})
export const fetchTokensList = () => async (dispatch) => {
  const { emitentId } = getState()
  dispatch(beginApiCallAction())
  try {
    const result = await getTokensList(emitentId)
    const tokensArr = result.map(token => ({
      emitentId: token.emitentId,
      activationTimestamp: token.activationTimestamp,
      amountPerReward: token.amountPerReward,
      valueInBaseToken: token.valueInBaseToken,
      description: token.description,
      promoTermsUrl: token.promoTermsUrl,
      logoFilename: token.logoFilename,
      tokenSymbol: token.tokenSymbol,
      name: token.name,
      amount: token.amount,
      tokensInCirculation: token.tokensInCirculation,
      generatedTokens: token.generatedTokens,
      expiryTimestamp: token.expiryTimestamp,
      tokenId: token.tokenId,
    }))
    dispatch(fetchTokensListSuccess(tokensArr))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}
export const fetchTokensNamesSuccess = namesArr => ({
  type: types.SET_TOKENS_NAMES_SUCCESS,
  names: namesArr,
})
export const fetchTokensNames = () => async (dispatch) => {
  const { emitentId } = getState()
  dispatch(beginApiCallAction())
  const result = await getTokensNames(emitentId)
  const namesArr = result.map(obj => ({
    name: obj.name,
    tokenSymbol: obj.tokenSymbol,
  }))
  dispatch(fetchTokensNamesSuccess(namesArr))
  dispatch(endApiCallAction())
}
export const setInitialTokenInfo = () => ({
  type: types.SET_INITIAL_TOKEN_INFO,
})
export const clearTokensNames = () => ({
  type: types.CLEAR_TOKENS_NAMES,
})
export const clearSelectedToken = () => ({
  type: types.CLEAR_SELECTED_TOKEN,
})
export const setInitialEditTokenResponse = () => ({
  type: types.SET_INITIAL_TOKEN_EDIT_RESPONSE,
})
export const clearTokenInfo = () => (dispatch) => {
  dispatch(setInitialEditTokenResponse())
  dispatch(setInitialTokenInfo())
  dispatch(clearSelectedToken())
  dispatch(clearTokensNames())
}
export const fetchTokenInfoSuccess = tokenInfoObj => ({
  type: types.SET_TOKEN_INFO_SUCCESS,
  tokenInfo: tokenInfoObj,
})
export const updateTokenInfo = async (tokenName, dispatch) => {
  const { emitentId } = getState()
  const result = await getTokenInfo(tokenName, emitentId)
  const tokenInfoObj = {
    tokenId: result.tokenId,
    name: result.name,
    tokenSymbol: result.tokenSymbol,
    logoFilename: result.logoFilename,
    emittedTokens: result.emittedTokens,
    generatedTokens: result.generatedTokens,
    tokensInActiveUnscannedCodes: result.tokensInActiveUnscannedCodes,
    tokensInCircl: result.tokensInCircl,
    scannedTokens: result.scannedTokens,
    tokensReturned: result.tokensReturned,
    tokensOnIssuerAccount: result.tokensOnIssuerAccount,
    tokensAvailableForCodes: result.tokensAvailableForCodes,
    emitentId: result.emitentId,
    activationTimestamp: result.activationTimestamp,
    amountPerReward: result.amountPerReward,
    valueInBaseToken: result.valueInBaseToken,
    description: result.description,
    promoTermsUrl: result.promoTermsUrl,
    expiryTimestamp: result.expiryTimestamp,
  }
  dispatch(fetchTokenInfoSuccess(tokenInfoObj))
}
export const fetchTokenInfoAndLoad = tokenName => async (dispatch) => {
  dispatch(beginApiCallAction())
  await updateTokenInfo(tokenName, dispatch)
  dispatch(endApiCallAction())
}

export const selectToken = tokenSymbol => ({
  type: types.SELECT_TOKEN,
  selectedToken: tokenSymbol,
})

export const selectCodeset = codeSet => ({
  type: types.SELECT_CODESET,
  selectedCodeset: codeSet,
})

export const fetchCodeSetListSuccess = codeSetArr => ({
  type: types.SET_CODE_SET_LIST_SUCCESS,
  codeSetList: codeSetArr,
})
export const fetchCodeSetList = (tokenSymbol = '') => async (dispatch) => {
  const { emitentId } = getState()
  dispatch(beginApiCallAction())
  try {
    const result = await getCodeSetList(tokenSymbol, emitentId)
    const codeSetArr = result.map(obj => ({
      id: obj.codeSetId,
      address: obj.address,
      activationTimestamp: obj.activationTimestamp,
      tokenSymbol: obj.tokenSymbol,
      name: obj.name,
      tokensPerCode: obj.tokensPerCode,
      tokensAmount: obj.tokensAmount,
      codes: obj.codes,
      isToppedUp: obj.isToppedUp,
      outputFilename: obj.outputFilename,
    }))
    dispatch(fetchCodeSetListSuccess(codeSetArr))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}

export const fetchTestTokenSuccess = result => ({
  type: types.SET_QR_HEX,
  data: result,
})

export const fetchTestToken = (tokenId = '', type = 'i', from, codeSetId) => async (dispatch) => {
  dispatch(beginApiCallAction())
  try {
    const result = await requestTestToken(tokenId, type, from, codeSetId)
    // const codeSetArr = result.map(obj => ({
    //   id: obj.codeSetId,
    //   activationTimestamp: obj.activationTimestamp,
    //   tokenSymbol: obj.tokenSymbol,
    //   name: obj.name,
    //   tokensPerCode: obj.tokensPerCode,
    //   tokensAmount: obj.tokensAmount,
    //   codes: obj.codes,
    //   isToppedUp: obj.isToppedUp,
    //   outputFilename: obj.outputFilename,
    // }))
    dispatch(fetchTestTokenSuccess(result))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}

export const onEmitentRegister = emitentData => async (dispatch) => {
  const { username, userEmail, emitentName } = emitentData
  const { publicKey } = JSON.parse(sessionLocalStore.getItem('session'))
  const response = await registerEmitent({
    username,
    userEmail,
    emitentName,
    publicKey,
  })
  if (response.errors) {
    dispatch(setRegistrationErrors(response.errors))
  } else {
    const { emitentId, emitentUserId } = response
    sessionLocalStore.setItem('emitentUserId', emitentUserId)
    sessionLocalStore.setItem('emitentId', emitentId)
    sessionLocalStore.setItem('username', username)
    dispatch(setEmitentId(emitentId))
    dispatch(setUsername(username))
    dispatch(reset('registerEmitent'))
    dispatch(setRegistrationErrors([]))
    dispatch(logInUser())
  }
}
export const onTokenSubmitSuccess = tokenCreateResponse => ({
  type: types.CREATE_NEW_TOKEN_SUCCESS,
  newToken: tokenCreateResponse,
})

export const clearNewTokenForm = () => ({ type: types.CLEAR_NEW_TOKEN_FORM })

export const onTokenSubmit = tokenData => async (dispatch) => {
  const { emitentId } = getState()
  dispatch(beginApiCallAction())
  try {
    const response = await createToken(
      {
        tokenSymbol: tokenData.tokenSymbol,
        name: tokenData.name,
        amount: tokenData.amount,
        amountPerReward: tokenData.amountPerReward,
        valueInBaseToken: tokenData.valueInBaseToken,
        description: tokenData.description,
        promoTermsUrl: tokenData.promoTermsUrl,
        logoFile: tokenData.logoFile[0],
        activationTimestamp: new Date(),
        expiryTimestamp: '2020-05-12',
        refId: tokenData.ref_id,
        technicalDetails: tokenData.technicalDetails,
        tokenType: tokenData.tokensType,
        tokenReturnTime: tokenData.expirationTime,
      },
      emitentId
    )
    const tokenCreateResponse = {
      error: mapNewTokenErrorToMessage(response.error),
      createdTokenId: response.createdTokenId,
    }
    dispatch(onTokenSubmitSuccess(tokenCreateResponse))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}

export const editTokenAction = (payloadToEdit, tokenSymbol) => async (dispatch) => {
  dispatch(setInitialEditTokenResponse())
  dispatch(beginUpdateTokenApiCallAction())
  const { emitentId } = getState()
  try {
    const response = await editToken(
      payloadToEdit, emitentId
    )
    dispatch({
      type: types.SET_EDITED_TOKEN_RESPONSE,
      error: response.error,
      hasGotResponse: true,
    })
    await updateTokenInfo(tokenSymbol, dispatch)
    dispatch(endUpdateTokenApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}

export const checkIsTokenCreationAuthorized = (
  checkIfShouldInterrupt,
  address,
  tokenId,
  amount
) => async (dispatch) => {
  try {
    const authorized = await isTokenCreationAuthorized(
      address,
      tokenId,
      amount
    )

    if (checkIfShouldInterrupt()) {
      return
    }

    dispatch({ type: types.SET_NEW_TOKEN_AUTH_STATE, authorized })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export const createCodeSet = ({
  name,
  tokenId,
  tokensPerCode,
  tokensAmount,
}) => async (dispatch) => {
  const response = await createNewCodeSet({
    name,
    tokenId,
    tokensPerCode,
    tokensAmount,
    isToppedUp: false,
    outputFilename: null,
    activationTimestamp: new Date(),
  })
  const codeSetCreateResponse = {
    error: response.error,
    createdCodeSetAddress: response.createdCodeSetAddress,
  }
  dispatch({ type: types.CREATE_NEW_CODE_SET, newCodeSet: codeSetCreateResponse })
}

export const clearNewCodeSet = () => ({
  type: types.CLEAR_NEW_CODE_SET,
})

export const clearIsCodeSetToppedUp = () => ({
  type: types.CLEAR_IS_CODE_SET_TOPPED_UP,
})

export const clearCodeSetError = () => ({
  type: types.CLEAR_CODE_SET_ERROR,
})

export const checkIfCodeSetIsToppedUp = (
  checkIfShouldInterrupt,
  address,
  tokenSymbol,
  amount
) => async (dispatch) => {
  let response
  try {
    response = await isCodeSetToppedUp(address, tokenSymbol, amount)
  } catch (e) {
    return dispatch({
      type: types.SET_CODE_SET_TX_INFO,
      error: e,
      toppedUp: false,
    })
  }
  if (checkIfShouldInterrupt()) {
    return dispatch({
      type: types.SET_CODE_SET_TX_INFO,
      error: null,
      toppedUp: false,
    })
  }
  return dispatch({
    type: types.SET_CODE_SET_TX_INFO,
    error: response.error,
    toppedUp: response.toppedUp,
  })
}
export const resetCreateTokenModal = () => async dispatch => dispatch({
  type: types.SET_NEW_TOKEN_AUTH_STATE,
  authorized: false,
})

export const setCodeSetOutputFilename = (
  checkIfShouldInterrupt,
  address,
  stop,
) => async (dispatch) => {
  let response
  try {
    response = await getCodeSetOutputFilename(address)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  if (checkIfShouldInterrupt()) {
    return
  }
  if (response.outputFilename) {
    stop()
  }
  dispatch({
    type: types.SET_CODE_SET_OUTPUT_FILE,
    outputFilename: response.outputFilename,
    id: response.id,
  })
}
export const fetchAudiobookData = pozId => async (dispatch) => {
  dispatch(beginApiCallAction())
  try {
    const result = await getAudiobook(pozId)
    const audiobooksData = {
      ref_id: result.poz_id,
      foto: `https://audiobooki.com/media/${result.foto}`,
      description: decodeURIComponent(result.opis).replace(/\+/g, ' '),
      name: decodeURIComponent(result.nazwa).replace(/\+/g, ' '),
      technicalDetails: result.search_string,
      tokenSymbol: result.poz_id,
      promoTermsUrl: `https://audiobooki.com/,${result.poz_id},2,#Login`,
    }
    dispatch(fetchAudiobookDataSuccess(audiobooksData))
    dispatch(endApiCallAction())
  } catch (err) {
    if (err.message === UNAUTHORIZED_FETCH_ERROR) {
      logOut(dispatch)
    }
  }
}
