import React from 'react'
import { Link } from 'react-router-dom'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'
import backArrow from 'icons/backArrow.svg'

const CreateTokenFeaturesContainer = () => (
  <FeaturesContainer heightInVh={7}>
    <TableDescription description={(
      <Link style={{ display: 'flex', justifyContent: 'baseline' }} to={`${process.env.PUBLIC_URL}/tokens`}>
        <img style={{ transform: 'scale(0.8)', marginRight: '20px' }} src={backArrow} alt="go back" />
        Creating new token
      </Link>
    )}
    />
  </FeaturesContainer>
)
export default CreateTokenFeaturesContainer
