import React from 'react'
import PropTypes from 'prop-types'

const TableContainer = ({
  children,
  columnWidths,
  columnNames,
  heightInVh,
  customStyles,
}) => {
  const headersList = columnNames.map(
    (name, index) => <div key={name} style={{ width: columnWidths[index] }}>{name}</div>
  )
  return (
    <>
      {' '}
      <div style={{
        width: '100%',
        height: `${heightInVh}vh`,
      }}
      >
        <div style={{
          width: '100%',
          height: '5vh',
          marginTop: '1vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          letterSpacing: '0.5px',
          fontSize: 'calc(0.4vw + 5px)',
          color: 'white',
          textAlign: 'center',
          fontFamily: 'LatoLatinWebBlack',
          textTransform: 'uppercase',
          ...customStyles,
        }}
        >
          {headersList}
        </div>
        <div style={{
          width: '100%',
          margin: '10px 0 20px 0',
          height: `${heightInVh}vh`,
        }}
        >
          <div style={{
            margin: '0 auto',
            minHeight: `calc(${heightInVh}vh - 8px)`,
            borderRadius: 5,
          }}
          >
            {children(columnWidths)}
          </div>
        </div>
      </div>
    </>
  )
}
TableContainer.propTypes = {
  children: PropTypes.func.isRequired,
  columnWidths: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  heightInVh: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  customStyles: PropTypes.object,
}
TableContainer.defaultProps = {
  heightInVh: null,
  customStyles: {},
}
export default TableContainer
