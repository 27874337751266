/* eslint-disable no-return-await */
import storage from './firebase'

export const uploadFile = async (file) => {
  const storageRef = storage.ref()
  return await storageRef.child(file.name).put(file).then(async res => res.ref.name).catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err)
    return null
  })
}
