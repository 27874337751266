/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import CreateTokenViewQRCode from '../CreateTokenViewQRCode'
import { checkIsTokenCreationAuthorized, resetCreateTokenModal, clearNewTokenForm } from '../../../redux/action'
import { createBackgroundProcess } from '../../../utils/async'
import { CHECK_IF_TOKEN_CREATION_TX_IS_ACCEPTED_INTERVAL } from '../../../utils/constants'
import deleteToken from '../../../api/deleteToken'
import cross from '../../../icons/cross.svg'
import style from './style.module.css'

class CreateTokenFinal extends React.Component {
  constructor(props) {
    super(props)

    this.createTokenAcceptedTransactionProcess = createBackgroundProcess(
      CHECK_IF_TOKEN_CREATION_TX_IS_ACCEPTED_INTERVAL, props.checkIsTokenCreationAuthorized
    )
  }

  componentDidMount() {
    this.setupBeforeUnload()
    const { from, tokenId, amount } = this.props
    this.createTokenAcceptedTransactionProcess.start(
      from,
      tokenId,
      amount
    )
  }

  componentDidUpdate() {
    if (this.props.isTokenCreationAuthorized) {
      this.createTokenAcceptedTransactionProcess.stop()
    }
  }

  componentWillUnmount() {
    const { tokenId, isTokenCreationAuthorized } = this.props
    if (!isTokenCreationAuthorized) {
      deleteToken(tokenId)
    }
    this.createTokenAcceptedTransactionProcess.stop()
    this.props.clearNewTokenForm()
  }

  setupBeforeUnload = () => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      const { tokenId, isTokenCreationAuthorized } = this.props
      if (!isTokenCreationAuthorized) {
        deleteToken(tokenId)
      }
    })
  }

  redirect = () => {
    const {
      isTokenCreationAuthorized, history, toggleVisibility,
    } = this.props
    if (isTokenCreationAuthorized) {
      this.props.resetCreateTokenModal()
      this.props.clearNewTokenForm()
      history.push(`${process.env.PUBLIC_URL}/tokens`)
    } else {
      toggleVisibility(false)
    }
  }

  render() {
    const {
      from,
      amount,
      tokenId,
      isTokenCreationAuthorized,
    } = this.props
    return (
      <div
        onClick={this.redirect}
        role="button"
        tabIndex={0}
        className={style.modal_background}
      >
        <PlainContainer
          widthInVw={40}
          heightInVh={65}
          customStyles={{
            top: '15vh',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <div
            onClick={(e) => { e.stopPropagation() }}
            role="button"
            tabIndex={0}
            className={style.modal}
          >
            <button
              type="button"
              onClick={this.redirect}
            >
              <img
                className={style.close}
                src={cross}
                alt="close window"
              />
            </button>
            {isTokenCreationAuthorized ? (
              <h2 className={style.notification}>
                The token has been successfully created
              </h2>
            )
              : (
                <>
                  <h2 className={style.header}>
                    Finalization of token creation
                  </h2>
                  <p className={style.instruction}>
                    Scan the code to add the newly created tokens to your wallet
                  </p>
                  <CreateTokenViewQRCode
                    txPayloadString={`t${JSON.stringify({ from, token_id: tokenId, amount })}`}
                  />
                </>
              )
            }
          </div>
        </PlainContainer>
      </div>
    )
  }
}
CreateTokenFinal.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  resetCreateTokenModal: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  tokenId: PropTypes.number.isRequired,
  amount: PropTypes.string.isRequired,
  checkIsTokenCreationAuthorized: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  isTokenCreationAuthorized: PropTypes.bool.isRequired,
  clearNewTokenForm: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({ isTokenCreationAuthorized: state.isTokenCreationAuthorized })
const mapDispatchToProps = {
  checkIsTokenCreationAuthorized,
  resetCreateTokenModal,
  clearNewTokenForm,
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTokenFinal))
