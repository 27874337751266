import React from 'react'
import TokenInfoViewBackground from './TokenInfoViewBackground'
import HorizontalDivider from '../../components/HorizontalDivider'
import TokenInfoContentContainer from './TokenInfoContentContainer'
import TokenInfoDataProvider from './TokenInfoDataProvider'
import TokenInfoFeaturesContainer from './TokenInfoFeaturesContainer'
import TokenInfoGridContainer from './TokenInfoGridContainer'
import TokenInfoFormContainer from './TokenInfoFormContainer'
import TokenInfoNumbDataContainer from './TokenInfoNumbDataContainer'

const TokenInfoView = () => (
  <>
    <TokenInfoViewBackground />
    <HorizontalDivider />
    <TokenInfoContentContainer>
      <TokenInfoDataProvider>
        <TokenInfoFeaturesContainer />
        <TokenInfoGridContainer>
          <TokenInfoFormContainer />
          <TokenInfoNumbDataContainer />
        </TokenInfoGridContainer>
      </TokenInfoDataProvider>
    </TokenInfoContentContainer>
  </>
)

export default TokenInfoView
