import * as types from '../action/types'

const initialState = 0

export const generalApiStatusReducer = (state = initialState, action) => {
  if (action.type === types.BEGIN_API_CALL) {
    return state + 1
  }
  if (action.type === types.LOG_OUT_USER) {
    return 0
  }
  if (action.type === types.END_API_CALL) {
    return state - 1
  }
  return state
}
export const updateTokenApiStatusReducer = (state = false, action) => {
  if (action.type === types.BEGIN_UPDATE_TOKEN_API_CALL) {
    return true
  }
  if (action.type === types.LOG_OUT_USER) {
    return false
  }
  if (action.type === types.END_UPDATE_TOKEN_API_CALL) {
    return false
  }
  return state
}
