import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DHunterLogo from './DHunterLogo'
import TopBarHeader from './TopBarHeader'

const LogoAndHeaderTopBar = (props) => {
  const { shouldHeaderRender } = props
  return (
    <React.Fragment>
      {shouldHeaderRender && <TopBarHeader header="Issuer's Panel" />}
      <DHunterLogo />
    </React.Fragment>
  )
}
LogoAndHeaderTopBar.propTypes = {
  shouldHeaderRender: PropTypes.bool.isRequired,
}
const mapStateToProps = state => ({
  shouldHeaderRender: !state.emitentId,
})
export default connect(mapStateToProps)(LogoAndHeaderTopBar)
