import { sessionLocalStore } from './sessionLocalStore'
import {
  LOG_OUT_USER,
  SET_EMITENT_ID,
  SET_SESSION_ID,
  SET_USERNAME,
} from '../redux/action/types'

export const logOut = (dispatch) => {
  sessionLocalStore.removeItem('session')
  sessionLocalStore.removeItem('emitentUserId')
  sessionLocalStore.removeItem('emitentId')
  sessionLocalStore.removeItem('username')
  dispatch({ type: SET_USERNAME, username: '' })
  dispatch({ type: SET_SESSION_ID, sessionId: '' })
  dispatch({ type: SET_EMITENT_ID, emitentId: null })
  dispatch({ type: LOG_OUT_USER })
}
