import React from 'react'
import PropTypes from 'prop-types'

const TopBarButton = ({
  iconSrc, label, width, ...props
}) => (
  <div
    {...props}
    style={{
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '2vw',
    }}
  >
    <div style={{
      height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <img
        src={iconSrc}
        alt=""
        style={{
          width: `${width}`,
        }}
      />
    </div>
    <div style={{
      marginTop: '5px',
      color: 'white',
      letterSpacing: '0.3px',
      fontSize: 'calc(0.5vw + 5px)',
    }}
    >
      {label}

    </div>
  </div>

)
TopBarButton.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  width: PropTypes.string,

}
TopBarButton.defaultProps = {
  width: 'calc(1.8vw + 10px)',
}
export default TopBarButton
