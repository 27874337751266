import React from 'react'
import PropTypes from 'prop-types'
import './popUp.css'

const FormPopUp = (props) => {
  const { error } = props
  if (!error) {
    return (
      <div className="edit_token_popup edit_token_success">
        <p style={{
          color: '#fff',
          textAlign: 'center',
        }}
        >
          Token has been updated successfully.
        </p>
      </div>
    )
  }
  if (error) {
    return (
      <div className="edit_token_popup edit_token_fail">
        <p>
          There was a problem with connecting to the server. Please try again.
        </p>
      </div>
    )
  }
  return null
}
FormPopUp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
}
FormPopUp.defaultProps = {
  error: null,
}
export default FormPopUp
