import React from 'react'
import PropTypes from 'prop-types'
import background from './background.svg'

const Background = ({ height }) => (
  <div style={{
    zIndex: -3,
    position: 'absolute',
    left: '-5px',
    top: '-5px',
    width: 'calc(100vw + 5px)',
    height,
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: ' left bottom',
  }}
  />
)

Background.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default Background
