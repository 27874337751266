import React from 'react'
import PropTypes from 'prop-types'
import { QRCode } from 'react-qr-svg'

const ReceiveViewQRCode = ({ txPayloadString }) => (
  <div style={{
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <QRCode
      bgColor="white"
      fgColor="#9d4e91"
      level="L"
      style={{ width: '75%' }}
      value={txPayloadString}
    />
  </div>
)

ReceiveViewQRCode.propTypes = {
  txPayloadString: PropTypes.string.isRequired,
}

export default ReceiveViewQRCode
