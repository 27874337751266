import * as types from '../action/types'

const initialState = []

export const audiobooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUDIOBOOK_DATA_SUCCESS:
      return {
        ref_id: action.audiobooksData.ref_id,
        name: action.audiobooksData.name,
        tokenSymbol: action.audiobooksData.tokenSymbol,
        valueInBaseToken: 1,
        description: action.audiobooksData.description,
        promoTermsUrl: action.audiobooksData.promoTermsUrl,
        amountPerReward: 1,
        amount: 0,
        logoFile: action.audiobooksData.foto,
        technicalDetails: action.audiobooksData.technicalDetails,
      }

    case types.RESET_AUDIOBOOK_DATA:
      return {
        ref_id: null,
        name: null,
        tokenSymbol: null,
        valueInBaseToken: null,
        description: null,
        promoTermsUrl: null,
        amountPerReward: null,
        amount: null,
        logoFile: null,
        technicalDetails: null,
      }

    default:
      return state
  }
}
