import * as types from '../action/types'

const initialState = ''

const sessionIdReducer = (state = initialState, action) => {
  if (action.type === types.SET_SESSION_ID) {
    return action.sessionId
  }
  return state
}
export default sessionIdReducer
