import React from 'react'
import TransactionsViewBackground from './TransactionsViewBackground'

const TransactionsView = () => (
  <>
    <TransactionsViewBackground />
    <div style={{ position: 'absolute', top: '50%', left: '50%' }}> Transactions View</div>
  </>
)
export default TransactionsView
