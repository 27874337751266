import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import PlainContainer from 'components/PlainContainer'
import CreateCodeSetFinal from '../CreateCodeSetFinal'
import CreateCodeSetErrorModal from '../CreateCodeSetErrorModal'
import {
  fetchTokensNames, selectToken, createCodeSet, clearCodeSetError,
} from '../../../redux/action'
import style from './style.module.css'

class CreateCodeSetFormContainer extends React.Component {
  state = { modalVisible: false }

  componentDidMount() {
    this.props.fetchTokensNames()
  }

  componentDidUpdate(prevProps) {
    const { tokensNames } = this.props
    if (tokensNames.length && (prevProps.tokensNames !== tokensNames)) {
      this.props.selectToken(this.props.tokensNames[0].tokenSymbol)
    }
  }

  setModalVisibility = (isVisible) => {
    this.setState({ modalVisible: isVisible })
  }

  hideModalAndClearError = () => {
    this.props.clearCodeSetError()
    this.setModalVisibility(false)
  }

  onSelectChange = (e) => {
    this.props.selectToken(e.target.value)
  }

  onSubmit = (formVal) => {
    this.props.createCodeSet({
      name: formVal.name,
      tokenId: this.props.tokenInfo.tokenId,
      tokensPerCode: formVal.tokensPerCode,
      tokensAmount: formVal.tokensPerCode * formVal.amountOfCodes,
    })
    this.setModalVisibility(true)
  }

  renderInput = formProp => (
    <div>
      <label className={style.label} htmlFor={formProp.name}>
        {formProp.label}
      </label>
      <input id={formProp.name} className={style.token_input} type="text" {...formProp.input} autoComplete="off" />
      <div className={style.error_message}>{this.renderError(formProp.meta)}</div>
    </div>
  )

  renderSelect = formProp => (
    <div>
      <label className={style.label} htmlFor={formProp.name}>
        {formProp.label}
      </label>
      <select
        id={formProp.name}
        className={style.token_select}
        style={{
          backgroundColor: '#DDDDDD',
        }}
        {...formProp.input}
        onChange={(value) => {
          formProp.input.onChange(value)
          this.onSelectChange(value)
        }}
      >
        {formProp.options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
      </select>
    </div>
  )

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>
    }
    return null
  }

  render() {
    const { tokensNames, formVal, newCodeSet } = this.props
    return (
      <>
        <PlainContainer
          widthInVw={32}
          customStyles={{
            borderRadius: '8px 0px 0px 8px',
            marginBottom: '3vh',
          }}
        >
          <form
            style={{
              fontSize: 'calc(0.5vw + 8px)',
              height: '80vh',
              padding: '40px 15%',
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'space-around',
            }}
            onSubmit={this.props.handleSubmit(this.onSubmit)}
          >
            <Field
              name="tokensname"
              component={this.renderSelect}
              label="tokens type"
              options={tokensNames.map(obj => ({
                value: obj.tokenSymbol,
                label: `${(obj.tokenSymbol).toUpperCase()}-${obj.name}`,
              }))
              }
            />
            <Field
              name="name"
              component={this.renderInput}
              label="name of set"
            />
            <Field
              name="tokensPerCode"
              component={this.renderInput}
              label="amount of tokens per code"
            />
            <Field
              name="amountOfCodes"
              component={this.renderInput}
              label="amount of codes"
            />
            <button
              style={{
                marginTop: '4vh',
                fontSize: '0.9em',
                color: '#fff',
                padding: '20px 0px',
                textTransform: 'uppercase',
                borderRadius: '8px',
                backgroundImage: 'linear-gradient(to right, #47428F ,#A84583)',
              }}
              type="submit"
            >
              start codes generation

            </button>
          </form>
        </PlainContainer>
        {this.state.modalVisible && newCodeSet.createdCodeSetAddress
          && (
            <CreateCodeSetFinal
              tokenId={this.props.tokenInfo.tokenId}
              tokenSymbol={formVal.values.tokensname}
              amount={parseInt(formVal.values.amountOfCodes, 10) * parseInt(formVal.values.tokensPerCode, 10)}
              address={this.props.newCodeSet.createdCodeSetAddress}
              hideModal={() => this.setModalVisibility(false)}
            />
          )
        }
        {this.state.modalVisible && newCodeSet.error
          && (
            <CreateCodeSetErrorModal
              clearErrors={this.props.clearCodeSetError}
              hideModal={() => this.setModalVisibility(false)}
            />
          )
        }
      </>
    )
  }
}
const formValidate = (formVal) => {
  const error = {}
  if (!formVal.name) {
    error.name = 'You must enter code set name'
  }
  if (!formVal.tokensPerCode) {
    error.tokensPerCode = 'You must enter the amount of tokens per code'
  }
  if (!formVal.amountOfCodes) {
    error.amountOfCodes = 'This field cannot be empty'
  }
  return error
}
const mapStateToProps = state => ({
  tokensNames: state.tokensNames,
  tokenInfo: state.tokenInfo,
  formVal: state.form.createCodeSet,
  newCodeSet: state.newCodeSet,
  initialValues: { tokensname: state.selectedToken },

})

const mapDispatchToProps = {
  fetchTokensNames,
  selectToken,
  createCodeSet,
  clearCodeSetError,
}
CreateCodeSetFormContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tokenInfo: PropTypes.object.isRequired,
  tokensNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchTokensNames: PropTypes.func.isRequired,
  selectToken: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createCodeSet: PropTypes.func.isRequired,
  clearCodeSetError: PropTypes.func.isRequired,
  newCodeSet: PropTypes.shape({
    error: PropTypes.object,
    createdCodeSetAddress: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formVal: PropTypes.object.isRequired,
}

const createCodeSetForm = reduxForm({
  form: 'createCodeSet',
  enableReinitialize: true,
  validate: formValidate,
})(CreateCodeSetFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(createCodeSetForm)
