import React from 'react'
import PropTypes from 'prop-types'
import { QRCode } from 'react-qr-svg'

const GenerateCodeSetQRCode = ({ faucet }) => (
  <div style={{
    paddingTop: '5vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <h3 style={{
      padding: '8px',
      wordWrap: 'break-word',
      width: '100%',
      textAlign: 'center',
    }}
    >
      { faucet.description }
    </h3>
    <h4 style={{
      padding: '8px',
      wordWrap: 'break-word',
      width: '80%',
      opacity: 0.6,
      textAlign: 'center',
    }}
    >
      { faucet.qrHex }
    </h4>
    <QRCode
      bgColor="white"
      fgColor="#9d4e91"
      level="L"
      style={{ maxWidth: '30%' }}
      value={faucet.qrHex}
    />
  </div>
)

GenerateCodeSetQRCode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  faucet: PropTypes.any.isRequired,
}

export default GenerateCodeSetQRCode
