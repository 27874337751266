import React from 'react'
import CodeSetsViewBackground from './CodeSetsViewBackground'
import CodeSetsFeaturesContainer from './CodeSetsFeaturesContainer'
import CodeSetsList from './CodeSetsList'
import CodeSetsContentContainer from './CodeSetsContentContainer'
import HorizontalDivider from '../../components/HorizontalDivider'

const CodeSetsView = () => (
  <>
    <CodeSetsViewBackground />
    <HorizontalDivider />
    <CodeSetsContentContainer>
      <CodeSetsFeaturesContainer />
      <CodeSetsList />
    </CodeSetsContentContainer>

  </>
)

export default CodeSetsView
