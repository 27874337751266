import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import TableContainer from 'components/TableContainer'
import TableEntry from 'components/TableEntry'
import { SERVER_URL } from 'utils/constants'
import { formatNumber } from '../../../helperFunctions'
import { fetchTokensList, selectToken } from '../../../redux/action'
import Loadable from '../../../components/Loadable'

class TokensList extends React.Component {
  componentDidMount() {
    this.props.fetchTokensList()
  }

  render() {
    const { tokensList } = this.props
    return (
      <Loadable>
        <TableContainer
          columnWidths={[
            '10%',
            '10%',
            '16%',
            '16%',
            '16%',
            '16%',
            '16%',
          ]}
          columnNames={[
            'id',
            'symbol',
            'name',
            'total tokens',
            'generated tokens',
            'tokens in circulation',
            'expiration date',
            // 'edit',
          ]}
          heightInVh={69}
        >
          {
            columnWidths => (
              tokensList.map((obj, index) => {
                const formatedObj = {
                  ...obj,
                  totalTokens: formatNumber(obj.amount),
                  tokensInCirculation: formatNumber(obj.tokensInCirculation),
                  generatedTokens: formatNumber(obj.generatedTokens),
                }
                return (
                  <Link to={`${process.env.PUBLIC_URL}/tokens/info/${formatedObj.tokenSymbol}`}>
                    <TableEntry
                      onClick={() => {
                        this.props.selectToken(formatedObj.tokenSymbol)
                      }}
                      styleOptions={{
                        cursor: 'pointer',
                      }}
                      badge={(
                        <img
                          style={{
                            borderRadius: '50%',
                            width: '5vh',
                            height: '5vh',
                            position: 'absolute',
                            boxShadow: '3px 2px 10px 1px rgba(0,0,0,0.32)',
                            transform: 'translate(-50%, -50%)',
                          }}
                          src={`${SERVER_URL}/getImage?imagePath=${obj.logoFilename}`}
                          alt="token"
                        />
                      )}
                      columnWidths={columnWidths}
                      rowLabels={
                        [
                          formatedObj.tokenId,
                          formatedObj.tokenSymbol,
                          formatedObj.name,
                          formatedObj.totalTokens,
                          formatedObj.generatedTokens,
                          formatedObj.tokensInCirculation,
                          formatedObj.expiryTimestamp,
                          // <img src={pen} alt="edit" />,
                        ]
                      }
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    />
                  </Link>
                )
              })
            )
          }
        </TableContainer>
      </Loadable>
    )
  }
}
TokensList.propTypes = {
  tokensList: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchTokensList: PropTypes.func.isRequired,
  selectToken: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
}
const mapStateToProps = state => ({ tokensList: state.tokensList })
const mapDispatchToProps = {
  fetchTokensList,
  selectToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(TokensList)

