import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'
import Button from 'components/Button'
import ButtonPlus from 'icons/plus.svg'
import CodeSetsCounter from '../CodeSetsCounter'
import { fetchTokensList } from '../../../redux/action'

class CodeSetsFeaturesContainer extends React.Component {
  componentDidMount() {
    this.props.fetchTokensList()
  }

  render() {
    const { isTokensListEmpty } = this.props
    return (
      <FeaturesContainer heightInVh={10}>
        <TableDescription description="List of all Code sets" />
        <TableDescription
          description={(
            <>
              Amount of generated Sets
              <CodeSetsCounter />
            </>
          )}
          styleOptions={{
            fontSize: 'calc(0.5vw + 5px)',
            position: 'absolute',
            top: '8vh',
          }}
        />
        <Link to={`${process.env.PUBLIC_URL}/codesets/create`}>
          <Button
            text={(
              <>
                Create a new set of codes
                <img
                  style={{
                    width: '1em',
                    height: '1em',
                    marginLeft: '15px',
                  }}
                  src={ButtonPlus}
                  alt="plus icon"
                />
              </>
            )}
            isDisabled={isTokensListEmpty}
          />
        </Link>
      </FeaturesContainer>
    )
  }
}
CodeSetsFeaturesContainer.propTypes = {
  fetchTokensList: PropTypes.func.isRequired,
  isTokensListEmpty: PropTypes.bool.isRequired,
}
const mapStateToProps = state => ({
  state,
  isTokensListEmpty: state.tokensList.length === 0,
})
const mapDispatchToProps = {
  fetchTokensList,
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeSetsFeaturesContainer)
