import * as types from '../action/types'

const initialState = ''

const selectCodesetReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_CODESET:
      return action.selectedCodeset
    case types.CLEAR_SELECTED_CODESET:
      return initialState
    default:
      return state
  }
}
export default selectCodesetReducer
