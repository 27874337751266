import { SERVER_URL } from '../utils/constants'

const createNewCodeSet = async ({
  tokenId,
  name,
  tokensPerCode,
  tokensAmount,
  activationTimestamp,
  isToppedUp,
  outputFilename,
}) => {
  const codeSetInput = {
    tokenId,
    name,
    tokensPerCode,
    tokensAmount,
    activationTimestamp,
    isToppedUp,
    outputFilename,
  }
  const response = await fetch(`${SERVER_URL}/createCodeSet`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(codeSetInput),
  })
  return response.json()
}

export default createNewCodeSet

