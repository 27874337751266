import React from 'react'
import CreateTokenViewBackground from './CreateTokenViewBackground'
import HorizontalDivider from '../../components/HorizontalDivider'
import CreateTokenContentContainer from './CreateTokenContentContainer'
import CreateTokenFeaturesContainer from './CreateTokenFeaturesContainer'
import CreateTokenFormContainer from './CreateTokenFormContainer'

const CreateTokenView = () => (
  <>
    <CreateTokenViewBackground />
    <HorizontalDivider />
    <CreateTokenContentContainer>
      <CreateTokenFeaturesContainer />
      <CreateTokenFormContainer />
    </CreateTokenContentContainer>
  </>
)

export default CreateTokenView
