import styled from 'styled-components'

const TopBarNavigationElement = styled.div`
    opacity:  ${props => (props.status === 'active' ? 1 : 0.6)};
    line-height: 34px;
    font-size: calc(0.5vw + 10px);
    color: white;
    width: 11.75vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    position: relative;
    cursor: pointer;

    &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left:0;
        width: 11.75vw;
        height: 0;
        box-sizing: border-box;
        border: 2px solid white;
        border-top-left-radius: ${props => (props.isFirst ? 10 : 0)}px;
        border-bottom-left-radius: ${props => (props.isFirst ? 10 : 0)}px;
        border-top-right-radius: ${props => (props.isLast ? 10 : 0)}px;
        border-bottom-right-radius: ${props => (props.isLast ? 10 : 0)}px;
    }

    &:hover {
        opacity: 1;
    }
`

export default TopBarNavigationElement
