import React from 'react'
import CreateCodeSetViewBackground from './CreateCodeSetViewBackground'
import HorizontalDivider from '../../components/HorizontalDivider'
import CreateCodeSetContentContainer from './CreateCodeSetContentContainer'
import CreateCodeSetFeaturesContainer from './CreateCodeSetFeaturesContainer'
import CreateCodeSetFormContainer from './CreateCodeSetFormContainer'
import CreateCodeSetDataContainer from './CreatCodeSetDataContainer'

const CreateCodeSetView = () => (
  <>
    <CreateCodeSetViewBackground />
    <HorizontalDivider />
    <CreateCodeSetContentContainer>
      <CreateCodeSetFeaturesContainer />
      <div style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '40% 60%',
      }}
      >
        <CreateCodeSetFormContainer />
        <CreateCodeSetDataContainer />
      </div>
    </CreateCodeSetContentContainer>
  </>

)
export default CreateCodeSetView
