import { SERVER_URL, /* LOCAL_SERVER_URL, */ UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const getAudiobook = async (pozId) => {
  const response = await fetch(`${SERVER_URL}/getAudiobook?pozId=${pozId}`)
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  if (response.status === 404) {
    return {}
  }
  return response.json()
}
export default getAudiobook
