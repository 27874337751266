import * as types from '../action/types'

const initialState = ''

const usernameReducer = (state = initialState, action) => {
  if (action.type === types.SET_USERNAME) {
    return action.username
  }
  return state
}
export default usernameReducer
