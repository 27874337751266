import { SERVER_URL } from '../utils/constants'

const isCodeSetToppedUp = async (address, tokenSymbol, amount) => {
  const response = await fetch(
    `${SERVER_URL}/isCodeSetToppedUp?address=${address}&tokenSymbol=${tokenSymbol}&amount=${amount}`
  )
  const payload = await response.json()
  if (response.status === 500) {
    throw new Error(payload.error)
  }
  return payload
}
export default isCodeSetToppedUp

