import React from 'react'
import TokensViewBackground from './TokensViewBackground'
import TokensFeaturesContainer from './TokensFeaturesContainer'
import TokensList from './TokensList'
import TokensContentContainer from './TokensContentContainer'
import HorizontalDivider from '../../components/HorizontalDivider'

const TokensView = () => (
  <>
    <TokensViewBackground />
    <HorizontalDivider />
    <TokensContentContainer>
      <TokensFeaturesContainer />
      <TokensList />
    </TokensContentContainer>

  </>
)

export default TokensView
