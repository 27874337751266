import React from 'react'
import { connect } from 'react-redux'
import Counter from 'components/Counter'
import PropTypes from 'prop-types'

const TokensCounter = ({ tokensTypesAmount }) => (
  <Counter amount={tokensTypesAmount} />
)
TokensCounter.propTypes = {
  tokensTypesAmount: PropTypes.number.isRequired,
}
const mapStateToProps = state => ({ tokensTypesAmount: state.tokensList.length })
export default connect(mapStateToProps)(TokensCounter)
