import React from 'react'

const HorizontalDivider = () => (
  <div style={{
    position: 'absolute',
    top: '15vh',
    left: '9vw',
    width: '85vw',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    opacity: 0.1,
    borderImage: 'linear-gradient(90deg,rgba(255,255,255,0) 0%,'
      + 'rgba(255,255,255,1) 15%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%)1',
  }}
  />
)
export default HorizontalDivider
