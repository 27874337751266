import React from 'react'
import { Link } from 'react-router-dom'
import PlainContainer from 'components/PlainContainer'

const FourOFourBackground = () => (
  <div
    style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
      height: '100vh',
      zIndex: 100,
      backgroundImage: 'linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35))',
    }}
  >
    <PlainContainer
      widthInVw={40}
      heightInVh={65}
      customStyles={{
        top: '15vh',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <>
        <h2 style={{
          fontWeight: 200,
          fontSize: 'calc(8vw + 9px)',
          color: '#4B4B4B',
        }}
        >
          404
        </h2>
        <p>The page that you tried to visit does not exist.</p>
        <Link to="/tokens">Click here to go to the Issuer&apos;s Panel dashboard</Link>
      </>
    </PlainContainer>
  </div>
)
export default FourOFourBackground
