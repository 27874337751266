// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TableContainer from 'components/TableContainer'
import TableEntry from 'components/TableEntry'
import { formatNumber } from '../../../helperFunctions'
import { fetchPartnersData } from '../../../redux/action'
import Loadable from '../../../components/Loadable'

class PartnersList extends React.Component {
  componentDidMount() {
    this.props.fetchPartnersData()
  }

  render() {
    const { partnersData } = this.props
    return (
      <Loadable>
        <TableContainer
          columnWidths={[
            '20%',
            '20%',
            '20%',
            '20%',
            '20%',
          ]}
          columnNames={[
            'name',
            'first return',
            'returned tokens',
            'value of returned tokens',
            'the average value of returned tokens per year',
          ]}
          heightInVh={69}
        >
          {
            columnWidths => (
              partnersData.map(partner => (
                <TableEntry
                  columnWidths={columnWidths}
                  rowLabels={[
                    partner.name,
                    partner.firstReturn,
                    formatNumber(partner.returnedTokens),
                    `${formatNumber(partner.returnedTokensVal)} PLN`,
                    `${formatNumber(partner.returnedTokensAvgValPerYear)} PLN`,
                  ]}
                  key={partner.name}
                />
              ))
            )
          }
        </TableContainer>
      </Loadable>
    )
  }
}

PartnersList.propTypes = {
  partnersData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    firstReturn: PropTypes.string,
    returnedTokens: PropTypes.number,
    returnedTokensVal: PropTypes.number,
    returnedTokensAvgValPerYear: PropTypes.number,

  })).isRequired,
  fetchPartnersData: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  partnersData: state.partnersData,
})
const mapDispatchToProps = {
  fetchPartnersData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnersList)
