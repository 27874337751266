import React from 'react'
import PropTypes from 'prop-types'

const Counter = ({ amount }) => (
  <div style={{
    display: 'inline-block',
    borderRadius: '8px',
    marginLeft: '10px',
    backgroundColor: 'rgba(255,255,255,0.05)',
    width: '1.5em',
    padding: '6px 0 ',
    color: '#fff',
    fontSize: 'calc(0.5vw + 10px)',
    textAlign: 'center',
  }}
  >
    {amount}
  </div>
)
Counter.propTypes = {
  amount: PropTypes.number.isRequired,
}
export default Counter
