import React from 'react'
import logoIcon from './logo.svg'

const DHunterLogo = () => (
  <div style={{
    position: 'absolute',
    left: '2vw',
    top: '20px',
  }}
  >
    <img
      src={logoIcon}
      style={{
        height: 'calc(1.6vw + 14px)',
      }}
      alt=""
    />
    <div style={{
      color: 'white',
      lineHeight: '22px',
      fontSize: 'calc(0.5vw + 8px)',
      textAlign: 'center',
      fontFamily: 'LatoLatinWebMedium',
      textTransform: 'uppercase',
      letterSpacing: '0.3px',

    }}
    >
      Issuer
    </div>
  </div>
)

export default DHunterLogo
