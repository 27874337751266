/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import PlainContainer from '../../../components/PlainContainer'
import { onEmitentRegister } from '../../../redux/action'
import { sessionLocalStore } from '../../../utils/sessionLocalStore'

class RegisterFormContainer extends React.Component {
  deleteSessionFromLocalStorage = (e) => {
    e.preventDefault()
    sessionLocalStore.removeItem('session')
  }

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.deleteSessionFromLocalStorage)
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.deleteSessionFromLocalStorage)
  }

  onSubmit = (formVal) => {
    const { onEmitentSubmit, history } = this.props
    onEmitentSubmit(formVal)
    history.push(`${process.env.PUBLIC_URL}/`)
  }

  renderInput = formProp => (
    <div>
      <label
        style={{
          color: '#98989B',
          display: 'block',
          textTransform: 'uppercase',
          fontSize: '0.6em',
          marginBottom: '10px',
        }}
        htmlFor={formProp.label}
      >
        {formProp.label}
      </label>
      <input
        id={formProp.label}
        style={{
          width: '100%',
          border: 'none',
          borderBottom: '1px solid #EFEFEF',
          fontSize: '1em',
        }}
        type="text"
        {...formProp.input}
        autoComplete="off"
      />
      <div style={{
        position: 'absolute',
        fontSize: '0.8em',
        color: 'rgb(148, 69, 69)',
      }}
      >
        {this.renderError(formProp.meta)}
      </div>
    </div>
  )

  renderError({ error, touched }) {
    if (touched && error) {
      return <div>{error}</div>
    }
    return null
  }

  render() {
    const { handleSubmit, invalid, errors } = this.props
    return (
      <>
        <PlainContainer
          heightInVh={60}
          widthInVw={75}
          customStyles={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <h2 style={{
            marginTop: '30px',
            textAlign: 'center',
            color: '#4F4D4E',
            fontFamily: 'LatoLatinWeb, sans-serif',
          }}
          >
            User / Emitent Registration
          </h2>
          <form
            style={{
              padding: '0 50px',
              display: 'flex',
              height: '70%',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignContent: 'center',
              fontSize: 'calc(0.5vw + 8px)',
              letterSpacing: '0.3px',
            }}
            onSubmit={
              handleSubmit(this.onSubmit)}
          >
            <Field
              name="username"
              component={this.renderInput}
              label="name"
            />
            <Field
              name="userEmail"
              component={this.renderInput}
              label="email address"
            />
            <Field
              name="emitentName"
              component={this.renderInput}
              label="Emitent name"
            />
            <button
              style={invalid ? {
                fontSize: '0.9em',
                color: '#fff',
                padding: '15px 65px',
                textTransform: 'uppercase',
                borderRadius: '8px',
                position: 'absolute',
                bottom: '-80px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundImage: 'linear-gradient(to right, #47428F ,#A84583 )',
                opacity: '0.4',
                cursor: 'not-allowed',
              } : {
                fontSize: '0.9em',
                color: '#fff',
                padding: '15px 65px',
                textTransform: 'uppercase',
                borderRadius: '8px',
                position: 'absolute',
                bottom: '-80px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundImage: 'linear-gradient(to right, #47428F ,#A84583 )',
              }}
              type="submit"
            >
              Register Emitent

            </button>
          </form>
          <p style={{
            marginTop: '30px',
            textAlign: 'center',
            color: '#4F4D4E',
            fontFamily: 'LatoLatinWeb, sans-serif',
            fontSize: 12,
          }}
          >
            After you register as a new User / Emitent, you will have to wait for your new account to be verified.
            <br />
            You will receive an email notification when your account is succesfully verified.
            <br />
            Only verfified users may login to the emitent panel.
          </p>
          <div style={{
            height: '10%',
          }}
          >
            <ul style={{
              listStyle: 'none',
              marginLeft: '20px',
            }}
            >
              {errors.map(error => (
                <li
                  key={error}
                  style={{
                    marginBottom: '10px',
                    fontSize: '0.8em',
                    color: 'rgb(148, 69, 69)',
                  }}
                >
                  {`${error}`}
                </li>
              ))}
            </ul>
          </div>
        </PlainContainer>
      </>
    )
  }
}

RegisterFormContainer.propTypes = {
  // redux form properties, docs: https://redux-form.com/7.4.2/docs/api/props.md/
  // eslint-disable-next-line react/forbid-prop-types
  formVal: PropTypes.object.isRequired,
  onEmitentSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.func.isRequired,
}

const formValidate = (formVal) => {
  const error = {}
  if (!formVal.username) {
    error.username = 'is required'
  }
  if (!formVal.userEmail) {
    error.userEmail = 'is required'
  }
  if (!formVal.emitentName) {
    error.emitentName = 'is required'
  }
  return error
}
const mapStateToProps = state => ({
  formVal: state.form.registerEmitent,
  errors: state.registrationErrors,
})
const mapDispatchToProps = {
  onEmitentSubmit: onEmitentRegister,
}
const connectWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFormContainer)
export default withRouter(reduxForm({
  form: 'registerEmitent',
  validate: formValidate,
  destroyOnUnmount: false,
})(connectWrapper))
