import * as types from '../action/types'

const initialState = {
  error: null,
  toppedUp: false,
}
const isCodeSetToppedUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CODE_SET_TX_INFO:
      return {
        error: action.error,
        toppedUp: action.toppedUp,
      }
    case types.CLEAR_IS_CODE_SET_TOPPED_UP:
      return {
        error: null,
        toppedUp: false,
      }
    default:
      return state
  }
}
export default isCodeSetToppedUpReducer
