import React from 'react'
import { Link } from 'react-router-dom'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'
import backArrow from 'icons/backArrow.svg'
import style from './style.module.css'

const CreateCodeSetFeaturesContainer = () => (
  <FeaturesContainer heightInVh={7}>
    <TableDescription description={(
      <Link className={style.link} to={`${process.env.PUBLIC_URL}/codesets`}>
        <img
          className={style.arrow}
          src={backArrow}
          alt="go back"
        />
        Code generation
      </Link>
    )}
    />
  </FeaturesContainer>
)
export default CreateCodeSetFeaturesContainer
