// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PlainContainer from 'components/PlainContainer'
import TableContainer from 'components/TableContainer'
import TableEntry from 'components/TableEntry'
// import checkedBox from 'icons/checkedBox.svg'
// import emptyBox from 'icons/emptyBox.svg'
import Loadable from 'components/Loadable'
import { Link } from 'react-router-dom'
import { SERVER_URL } from '../../../utils/constants'
import { fetchTokenInfoAndLoad, fetchCodeSetList, clearTokenInfo } from '../../../redux/action'
import { formatNumber } from '../../../helperFunctions'
import style from './style.module.css'

class CreateCodeSetDataContainer extends React.Component {
  componentDidMount() {
    const { selectedToken } = this.props
    this.props.fetchCodeSetList(selectedToken)
  }

  componentDidUpdate(prevProps) {
    const { selectedToken } = this.props
    if (selectedToken && (prevProps.selectedToken !== selectedToken)) {
      this.props.fetchTokenInfoAndLoad(selectedToken)
      this.props.fetchCodeSetList(selectedToken)
    }
  }

  componentWillUnmount() {
    this.props.clearTokenInfo()
  }

  render() {
    const { tokenInfo, codeSetList } = this.props
    return (
      <PlainContainer
        customStyles={{
          borderRadius: '0px 8px 8px 0px',
          marginBottom: '3vh',
        }}
        widthInVw={50}
      >
        <Loadable>
          {tokenInfo.logoFilename ? (
            <>
              <div
                className={style.tokenInfo_data}
              >
                <div className={style.tokenInfo_inner}>
                  <img
                    className={style.token_img}
                    src={`${SERVER_URL}/getImage?imagePath=${tokenInfo.logoFilename}`}
                    alt="token symbol"
                  />
                  <Link to={`${process.env.PUBLIC_URL}/tokens/info/${tokenInfo.tokenSymbol}`}>
                    {(tokenInfo.tokenSymbol).toUpperCase()}
                  </Link>
                </div>
                <div>
                  <span>total tokens</span>
                  <span>{formatNumber(tokenInfo.emittedTokens)}</span>
                </div>
                <div>
                  <span>total tokens in codes</span>
                  <span>{formatNumber(tokenInfo.generatedTokens)}</span>
                </div>
                <div>
                  <span>tokens in active unscanned codes</span>
                  <span>{formatNumber(tokenInfo.tokensInActiveUnscannedCodes)}</span>
                </div>
                <div>
                  <span>tokens in circulation</span>
                  <span>{formatNumber(tokenInfo.tokensInCircl)}</span>
                </div>
                <div>
                  <span>scanned tokens</span>
                  <span>{formatNumber(tokenInfo.scannedTokens)}</span>
                </div>
                {/* <div>
                  <span>returned tokens</span>
                  <span>{formatNumber(tokenInfo.tokensReturned)}</span>
                </div> */}
                <div>
                  <span>tokens on issuer account</span>
                  <span>{formatNumber(tokenInfo.tokensOnIssuerAccount)}</span>
                </div>
                {/* <div>
                  <span>tokens available for codes</span>
                  <span>{formatNumber(tokenInfo.tokensAvailableForCodes)}</span>
                </div> */}
              </div>
              <div className={style.text_box}>
                <p>
                  Hitherto generated code sets
                </p>
                <TableContainer
                  columnWidths={[
                    '18%',
                    '20%',
                    '18%',
                    '16%',
                    '12%',
                    '16%',
                  ]}
                  columnNames={[
                    'date',
                    'name',
                    'codes',
                    'tokens',
                    // 'topped up',
                    'download',
                  ]}
                  customStyles={{
                    color: '#5C448A',
                  }}
                >
                  {
                    columnWidths => (
                      codeSetList.map((obj, i) => (
                        <TableEntry
                          columnWidths={columnWidths}
                          styleOptions={{
                            fontSize: 'calc(0.5vw + 4px)',
                          }}
                          rowLabels={[
                            obj.activationTimestamp,
                            obj.name,
                            formatNumber(obj.codes),
                            formatNumber(obj.tokensAmount),
                            // <img
                            //   style={{
                            //     transform: 'scale(0.6)',
                            //   }}
                            //   src={obj.isToppedUp ? checkedBox : emptyBox}
                            //   alt="check box"
                            // />,
                            obj.outputFilename
                              ? (
                                <Link to={`${process.env.PUBLIC_URL}/codesetOutput/${obj.outputFilename}`}>
                                  Click to download
                                </Link>
                              )
                              : 'Generating...',
                          ]}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                        />
                      ))
                    )
                  }
                </TableContainer>
              </div>
            </>
          ) : null
          }
        </Loadable>
      </PlainContainer>
    )
  }
}
CreateCodeSetDataContainer.propTypes = {
  codeSetList: PropTypes.arrayOf(PropTypes.object).isRequired,
  tokenInfo: PropTypes.oneOfType([PropTypes.shape({
    name: PropTypes.string,
    tokenSymbol: PropTypes.string,
    logoFilename: PropTypes.string,
    emittedTokens: PropTypes.number,
    generatedTokens: PropTypes.number,
    tokensInActiveUnscannedCodes: PropTypes.number,
    tokensInCircl: PropTypes.number,
    scannedTokens: PropTypes.number,
    tokensReturned: PropTypes.number,
    tokensOnIssuerAccount: PropTypes.number,
    tokensAvailableForCodes: PropTypes.number,
  }),
  PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tokenSymbol: PropTypes.string,
    logoFilename: PropTypes.string,
    emittedTokens: PropTypes.number,
    generatedTokens: PropTypes.number,
    tokensInActiveUnscannedCodes: PropTypes.number,
    tokensInCircl: PropTypes.number,
    scannedTokens: PropTypes.number,
    tokensReturned: PropTypes.number,
    tokensOnIssuerAccount: PropTypes.number,
    tokensAvailableForCodes: PropTypes.number,
  })),
  ]).isRequired,
  selectedToken: PropTypes.string.isRequired,
  fetchCodeSetList: PropTypes.func.isRequired,
  fetchTokenInfoAndLoad: PropTypes.func.isRequired,
  clearTokenInfo: PropTypes.func.isRequired,
}
const mapStateToProps = state => ({
  selectedToken: state.selectedToken,
  tokenInfo: state.tokenInfo,
  codeSetList: state.codeSetList,
})
const mapDispatchToProps = {
  fetchTokenInfoAndLoad,
  fetchCodeSetList,
  clearTokenInfo,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCodeSetDataContainer)
