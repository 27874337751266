const { NODE_ENV } = process.env

export const LOCAL_SERVER_URL = 'http://localhost:3001'

export const SERVER_URL = (() => {
  const { REACT_APP_SERVER_URL } = process.env

  if (NODE_ENV === 'production' && REACT_APP_SERVER_URL === undefined) {
    return 'https://prod-dhunter.ew.r.appspot.com'
  }

  if (REACT_APP_SERVER_URL === undefined) {
    return 'https://prod-dhunter.ew.r.appspot.com'
  }

  return REACT_APP_SERVER_URL
})()
export const CHECK_IF_TOKEN_CREATION_TX_IS_ACCEPTED_INTERVAL = 500
export const SET_CODE_SET_OUTPUT_FILENAME_INTERVAL = 500
export const CHECK_IF_CODESET_CREATION_TX_IS_IN_BALANCES_INTERVAL = 500
export const CHECK_IF_AUTHORIZED_SESSION_OBJECT_IS_AVAILABLE_INTERVAL = 500
export const UNAUTHORIZED_FETCH_ERROR = 'Unauthorized to fetch resource'
export const TOKEN_CREATION_ERRORS = {
  TOKEN_SYMBOL_EXISTS: 'TOKEN_SYMBOL_EXISTS',
}
