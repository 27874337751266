import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  fetchTokenInfoAndLoad, clearTokenInfo, fetchTokensNames, selectToken,
} from '../../../redux/action'

class GenerateCodetSetDataProvider extends React.Component {
  componentDidMount() {
    const { selectedToken } = this.props
    this.props.fetchTokensNames()
    const currentTokenSymbol = this.props.history.location.pathname.split('/').slice(-1)[0]
    this.props.selectToken(currentTokenSymbol)
    if (selectedToken) {
      this.props.fetchTokenInfoAndLoad(selectedToken)
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedToken } = this.props
    if (prevProps.selectedToken !== selectedToken) {
      this.props.fetchTokenInfoAndLoad(selectedToken)
    }
  }

  componentWillUnmount() {
    this.props.clearTokenInfo()
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }
}
const mapStateToProps = state => ({ selectedToken: state.selectedToken })
const mapDispatchToProps = {
  fetchTokenInfoAndLoad,
  clearTokenInfo,
  fetchTokensNames,
  selectToken,
}
GenerateCodetSetDataProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  selectedToken: PropTypes.string.isRequired,
  selectToken: PropTypes.func.isRequired,
  fetchTokensNames: PropTypes.func.isRequired,
  fetchTokenInfoAndLoad: PropTypes.func.isRequired,
  clearTokenInfo: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateCodetSetDataProvider))
