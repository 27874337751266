import React from 'react'
import PropTypes from 'prop-types'
import PlainContainer from 'components/PlainContainer'
import cross from '../../../icons/cross.svg'
import style from './style.module.css'

const CreateCodeSetErrorModal = ({ clearErrors, hideModal }) => (
  <div
    role="button"
    tabIndex={0}
    onClick={() => {
      clearErrors()
      hideModal()
    }}
    className={style.modal_background}
  >
    <PlainContainer
      heightInVh={60}
      widthInVw={30}
      customStyles={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}
    >
      <div
        onClick={(e) => { e.stopPropagation() }}
        role="button"
        tabIndex={0}
        className={style.modal}
      >
        <button
          type="button"
          onClick={() => {
            clearErrors()
            hideModal()
          }}
        >
          <img
            className={style.close}
            src={cross}
            alt="close window"
          />
        </button>
        <p className={style.notification}>
          There was a problem with connecting to the server.
          Please try again and make sure you have internet connection.
        </p>
      </div>
    </PlainContainer>
  </div>

)

CreateCodeSetErrorModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
}

export default CreateCodeSetErrorModal
