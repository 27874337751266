import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const getTokenInfo = async (tokenSymbol, emitentId) => {
  const response = await fetch(
    `${SERVER_URL}/getToken?tokenSymbol=${tokenSymbol}
    &emitentId=${emitentId}
    &authSession=${sessionLocalStore.getItem('session')}`
  )
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  if (response.status === 404) {
    return {}
  }
  return response.json()
}
export default getTokenInfo
