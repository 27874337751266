import React from 'react'
import PropTypes from 'prop-types'
import Loadable from '../../../components/Loadable'

const TokenInfoGridContainer = props => (
  <Loadable>
    <div style={{
      width: '100%',
      height: '150vh',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '42% 16% 42%',
      gridColumnGap: '10px',
    }}
    >
      {props.children}
    </div>
  </Loadable>
)
TokenInfoGridContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

}

export default TokenInfoGridContainer
