import { sessionLocalStore } from 'utils/sessionLocalStore'
import { SERVER_URL, UNAUTHORIZED_FETCH_ERROR } from '../utils/constants'

const getCodeSetList = async (tokenSymbol = '', emitentId) => {
  let response
  if (tokenSymbol) {
    response = await fetch(
      `${SERVER_URL}/getCodeSetList/${tokenSymbol.toUpperCase()}
      ?emitentId=${emitentId}
      &authSession=${sessionLocalStore.getItem('session')}`
    )
  } else {
    response = await fetch(`${SERVER_URL}/getCodeSetList?emitentId=${emitentId}
    &authSession=${sessionLocalStore.getItem('session')}`)
  }
  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_FETCH_ERROR)
  }
  if (response.status === 404) {
    return []
  }
  return response.json()
}
export default getCodeSetList
