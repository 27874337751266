import React from 'react'
import PropTypes from 'prop-types'
import ContentContainer from 'components/ContentContainer'

const RegisterEmitentContentContainer = ({ children }) => (
  <ContentContainer top="calc(23vh - 40px)">
    {children}
  </ContentContainer>
)

RegisterEmitentContentContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default RegisterEmitentContentContainer
