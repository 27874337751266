import * as types from '../action/types'

export const codeSetListReducer = (state = [], action) => {
  switch (action.type) {
    case types.SET_CODE_SET_LIST_SUCCESS:
      return action.codeSetList.map(codeSet => ({
        id: codeSet.id,
        address: codeSet.address,
        activationTimestamp: codeSet.activationTimestamp,
        tokenSymbol: codeSet.tokenSymbol,
        name: codeSet.name,
        tokensPerCode: codeSet.tokensPerCode,
        tokensAmount: codeSet.tokensAmount,
        codes: codeSet.codes,
        isToppedUp: codeSet.isToppedUp,
        outputFilename: codeSet.outputFilename,
      }))
    case types.SET_CODE_SET_OUTPUT_FILE: {
      if (!action.outputFilename) {
        return state
      }
      return state.map((codeSet) => {
        if (codeSet.id === action.id) {
          return {
            id: codeSet.id,
            address: codeSet.address,
            activationTimestamp: codeSet.activationTimestamp,
            tokenSymbol: codeSet.tokenSymbol,
            name: codeSet.name,
            tokensPerCode: codeSet.tokensPerCode,
            tokensAmount: codeSet.tokensAmount,
            codes: codeSet.codes,
            isToppedUp: codeSet.isToppedUp,
            outputFilename: action.outputFilename,
          }
        }
        return codeSet
      })
    }
    default:
      return state
  }
}

export const createCodeSetReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_NEW_CODE_SET:
      return {
        error: action.newCodeSet.error,
        createdCodeSetAddress: action.newCodeSet.createdCodeSetAddress,
      }
    case types.CLEAR_NEW_CODE_SET:
      return {
        error: null,
        createdCodeSetAddress: null,
      }
    case types.CLEAR_CODE_SET_ERROR:
      return {}
    default:
      return state
  }
}
