import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import partnersReducer from './partnersReducer'
import { codeSetListReducer, createCodeSetReducer } from './codeSetReducer'
import {
  tokensListReducer, tokenInfoReducer, tokensNamesReducer, createTokenReducer, editTokenReducer,
} from './tokensReducer'
import sessionIdReducer from './sessionIdReducer'
import selectTokenReducer from './selectTokenReducer'
import selectCodesetReducer from './selectCodesetReducer'
import isCodeSetToppedUpReducer from './isCodeSetToppedUpReducer'
import loginReducer from './loginReducer'
import isTokenCreationAuthorized from './newTokenAuthReducer'
import emitentIdReducer from './emitentIdReducer'
import registrationReducer from './registrationReducer'
import usernameReducer from './usernameReducer'
import faucetReducer from './faucetReducer'
import { generalApiStatusReducer, updateTokenApiStatusReducer } from './apiStatusReducer'
import { audiobooksReducer } from './audiobooksReducer'

const reducer = combineReducers({
  emitentId: emitentIdReducer,
  userLogin: loginReducer,
  sessionId: sessionIdReducer,
  partnersData: partnersReducer,
  tokensList: tokensListReducer,
  tokenInfo: tokenInfoReducer,
  tokensNames: tokensNamesReducer,
  selectedToken: selectTokenReducer,
  selectedCodeset: selectCodesetReducer,
  codeSetList: codeSetListReducer,
  newToken: createTokenReducer,
  editedToken: editTokenReducer,
  newCodeSet: createCodeSetReducer,
  audiobooks: audiobooksReducer,
  faucet: faucetReducer,
  form: formReducer,
  isCodeSetToppedUp: isCodeSetToppedUpReducer,
  isTokenCreationAuthorized,
  registrationErrors: registrationReducer,
  username: usernameReducer,
  apiCallsInProgress: generalApiStatusReducer,
  isTokenBeingUpdated: updateTokenApiStatusReducer,
})

export default reducer
