import React from 'react'
import FeaturesContainer from 'components/FeaturesContainer'
import TableDescription from 'components/TableDescription'

const PartnersFeaturesContainer = () => (
  <FeaturesContainer heightInVh={6} styleOptions={{ paddingTop: '45px', marginBottom: '3.5vh' }}>
    <TableDescription description="List of all Partners" />
  </FeaturesContainer>
)
export default PartnersFeaturesContainer
