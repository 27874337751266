import { SERVER_URL } from '../utils/constants'

const getCodeSetOutputFilename = async (address) => {
  const response = await fetch(
    `${SERVER_URL}/getCodeSetOutputFilename?address=${address}`
  )
  const payload = await response.json()
  if (response.status === 500) {
    throw new Error(payload.error)
  }
  return payload
}
export default getCodeSetOutputFilename
