import React from 'react'
import PropTypes from 'prop-types'

const TopBarHeader = ({ header }) => (
  <div style={{
    marginTop: '25px',
    width: '100%',
    color: 'white',
    letterSpacing: '0.3px',
    fontSize: 'calc(1vw + 5px)',
  }}
  >
    <h1 style={{
      textAlign: 'center',
    }}
    >
      {header}
    </h1>
  </div>
)

TopBarHeader.propTypes = {
  header: PropTypes.string.isRequired,
}

export default TopBarHeader
