import React from 'react'
import HorizontalDivider from '../../components/HorizontalDivider'
import GenerateCodeSetViewBackground from './GenerateCodeSetViewBackground'
import GenerateCodeSetContentContainer from './GenerateCodeSetContentContainer'
import GenerateCodeSetFeaturesContainer from './GenerateCodeSetFeaturesContainer'
import GenerateCodeSetFaucet from './GenerateCodeSetFaucet'
import GenerateCodeSetDataProvider from './GenerateCodeSetDataProvider'

const GenerateCodeSetView = () => (
  <>
    <GenerateCodeSetViewBackground />
    <HorizontalDivider />
    <GenerateCodeSetContentContainer>
      <GenerateCodeSetDataProvider>
        <GenerateCodeSetFeaturesContainer />
        <GenerateCodeSetFaucet />
      </GenerateCodeSetDataProvider>
    </GenerateCodeSetContentContainer>
  </>

)
export default GenerateCodeSetView
